import React, { useState, useEffect } from "react";
import { ThemeContext, themes } from "../../contexts/ThemeContext.js";

export default function ThemeContextWrapper(props) {
  const [theme, setTheme] = useState(themes.dark);

  function changeTheme(theme) {
    setTheme(theme);
  }

  useEffect(() => {
    switch (theme) {
      case themes.light:
        document.body.classList.add("white-content");
        document.body.style.backgroundColor = 'white';
        document.body.style.backgroundImage = "linear-gradient(to bottom right, #a1c4fd, #c2e9fb)";
        // document.body.style.backgroundImage = "linear-gradient(to bottom right, #f5f7fa, #c3cfe2)";
        document.body.style.height = "100vh";
        break;
      case themes.dark:
      default:
        document.body.classList.remove("white-content");
        document.body.style.backgroundColor = 'white';
        // document.body.style.backgroundImage = "linear-gradient(to bottom right, #c7eafd, #537895)";
        document.body.style.backgroundImage = "linear-gradient(to bottom right, #53789570, #EDF3F970)";
        document.body.style.height = "100vh";
        break;
    }
  }, [theme]);

  return (
    <ThemeContext.Provider value={{ theme: theme, changeTheme: changeTheme }}>
      {props.children}
    </ThemeContext.Provider>
  );
}

import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import MediaQuery from 'react-responsive';

import { confirmStripePromo, updateStripeSubscriptionPromo } from "../../firebase.js";

import TextField from '@mui/material/TextField';
import { PaperButton } from './buttonset';

import styled from 'styled-components'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faRotate
} from "@fortawesome/free-solid-svg-icons";

library.add([faRotate]);

const ModTextField = styled(TextField)({
  '& .MuiFormHelperText-root.Mui-error': {
    position: 'absolute',
    top: '100%'
  }
});

const CouponButton = styled.div`
  position: relative;
  color: white;
  background-color: #0570de;

  min-width: 50px;
  height: 40px;

  &:hover {
    transition: .3s;
    background-color: #0570deCC;
  }

  border-radius: 0px 8px 8px 0px;
  border: none;

  font-family: Open Sans;
  font-size: 18px;
  font-weight: bold;

  cursor: ${(props)=>props.disabled ? 'not-allowed' : 'default'};

  display: flex;
  justify-content: center;
  align-items: center;

  transition: .3s;
`;

const ButtonTitle = styled.div`
  font-size: 14px;
  padding: 0px 15px 0px 15px;
`;

function CouponElement(props) {
  const [checking, setChecking] = useState(false);
  const [btnHover, setBtnHover] = useState(null);

  const [promotion_code, setPromotionCode] = useState(null);
  const [helperText, setHelperText] = useState(null);
  const [promoState, setPromoState] = useState(null);

  function CheckCoupon() {
    setChecking(true);

    confirmStripePromo(promotion_code)
    .then((r) => {
      if (r.error) {
        console.log(r.error.message);
      } else {
        if (r.data.data.length > 0) {
          if (r.data.data[0].active) {
            console.log(r.data.data[0].id);
            props.applyPromotionCode(r.data.data[0].id);
            if (r.data.data[0].coupon.amount_off){
              // setHelperText(`Coupon applied: $${r.data.data[0].coupon.amount_off} off!`)
              setPromoState("success");
              props.updateActivePrice(props.originalPrice - r.data.data[0].coupon.amount_off);
            } else if (r.data.data[0].coupon.percent_off){
              // setHelperText(`Coupon applied: ${r.data.data[0].coupon.percent_off}% off!`)
              setPromoState("success");
              props.updateActivePrice((props.originalPrice * r.data.data[0].coupon.percent_off/100) - props.originalPrice);
            }
          } else {
            setHelperText("Invalid Coupon Code");
            setPromoState("error");
          }
        } else {
          setHelperText("Invalid Coupon Code");
          setPromoState("error");
        }
      }
      setChecking(false);
    })
    .catch(function(error) {
      console.log(error.message);
    })
  }

  return (
    <>
      <MediaQuery minWidth={1000} style={{position: "relative"}}>
        <div style={{position: "relative", display: "flex", flexDirection: "row", justifyContent: "end", alignContent: "center", marginTop: "20px"}} >
          <ModTextField id="outlined-basic" value={props.value} error={promoState==="error"} color={promoState} variant="outlined" placeholder="Promo Code" size="small" disabled={checking} onChange={(e) => { setPromotionCode(e.target.value)}} helperText={helperText}/>
          <CouponButton onClick={CheckCoupon} onMouseEnter={() => setBtnHover(true)} onMouseLeave={() => setBtnHover(false)}>
            {checking ? <FontAwesomeIcon icon={faRotate} spin/> : <ButtonTitle> Apply </ButtonTitle>}
          </CouponButton>
        </div>
      </MediaQuery>
      <MediaQuery maxWidth={999}>
        <div style={{position: "relative", display: "flex", flexDirection: "row", justifyContent: "end", alignContent: "center", marginTop: "20px"}} >
          <ModTextField id="outlined-basic" value={props.value} error={promoState==="error"} color={promoState} variant="outlined" placeholder="Promo Code" size="small" disabled={checking} onChange={(e) => { setPromotionCode(e.target.value)}} helperText={helperText}/>
          <CouponButton onClick={CheckCoupon} onMouseEnter={() => setBtnHover(true)} onMouseLeave={() => setBtnHover(false)}>
            {checking ? <FontAwesomeIcon icon={faRotate} spin/> : <ButtonTitle> Apply </ButtonTitle>}
          </CouponButton>
        </div>
      </MediaQuery>
    </>
    );
};



export {
  CouponElement
};

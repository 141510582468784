import ReactDOM from "react-dom";

import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const Border = styled.div`
  border-bottom: 1px solid #00000050;
  width: 100%;
`;

const Content = styled.span`
  color: #00000050;
  padding: 0 10px 0 10px;
`;

const style = {

}

function Divider(props) {

  return (
    <Container>
      <Border />
      <Content>
        {props.children}
      </Content>
      <Border />
    </Container>
    );
};

function FullDivider(props) {

  return (
    <Container style={props.style}>
      <Border />
      <Border />
    </Container>
    );
};

export {
  Divider,
  FullDivider
};

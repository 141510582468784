import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import TextField from '@mui/material/TextField';
import Switch from 'react-ios-switch';
import { MuiTelInput, matchIsValidTel } from 'mui-tel-input'

import { sendTextVerificationLocal, sendEmailVerificationLocal } from '../../firebase';

import styled from 'styled-components'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faRotate
} from "@fortawesome/free-solid-svg-icons";

library.add([faRotate]);

const SFContainer = styled.div`
  font-family: Open Sans;
  font-size: 16px;
  font-weight: bold;
  color: black;

  display: flex;
  flex-direction: row;

  align-items: center;

  margin-top: 15px;
  margin-left: 10px;
`;

const SFBorder = styled.div`
  border-bottom: 1px solid #00000050;
  width: 100%;
`;

const SFContent = styled.span`
  color: #00000050;
  padding: 0 10px 0 10px;
`;

const ValidateButton = styled.button`
  width: 80px;
  height: 40px;

  color: white;
  background-color: #0570de;

  font-family: Open Sans;
  font-size: 18px;
  font-weight: bold;

  border-radius: 8px;
  border: none;

  margin-right: 10px;

  &:hover {
    transition: .3s;
    background-color: #0570deCC;
  }

  transition: .3s;
`;


function ValidatePhone(props) {
  const [readyToVerify, setReadyToVerify] = useState(false);
  const [verifying, setVerifying] = useState(false);
  const [verificationCode, setVerificationCode] = useState("");

  useEffect(() => {

    if (matchIsValidTel(props.phone)) {
      setReadyToVerify(true);
    } else {
      setReadyToVerify(false);
    }
  }, [props.phone])
  function beginVerification(){
    setVerifying(true);
    alert(`Verification text sent to ${props.phone}`);
    sendTextVerificationLocal(props.phone)
      .then((r) => {
        // console.log(r);
        setVerificationCode(r.data.code);
      })
  }

  function validate(value) {
    if (value == verificationCode) {
      setVerifying(false);
      setReadyToVerify(false);
      setVerificationCode("");
      props.onValidate(true);
    }
  }

  return (
    <SFContainer style={{...props.style}}>
      {readyToVerify && (
        <>
          <ValidateButton disabled={verifying} onClick={beginVerification}>
            {verifying ? <FontAwesomeIcon icon={faRotate} spin color={"white"} size={"1x"}/> : "Verify"}
          </ValidateButton>
          <>
            {verifying && (
              <TextField id="outlined-basic" value={props.value} variant="outlined" size="small" onChange={(e) => { validate(e.target.value)}}/>
            )}
          </>
        </>
      )}
    </SFContainer>
    );
};

function ValidateEmail(props) {
  const [readyToVerify, setReadyToVerify] = useState(false);
  const [verifying, setVerifying] = useState(false);
  const [verificationCode, setVerificationCode] = useState("");

  useEffect(() => {
    // don't remember from where i copied this code, but this works.
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if ( re.test(props.email) ) {
        // this is a valid email address
        // call setState({email: email}) to update the email
        setReadyToVerify(true);
    }
    else {
        // invalid email, maybe show an error to the user.
        setReadyToVerify(false);
    }
  }, [props.email])
  function beginVerification(){
    setVerifying(true);
    alert(`Verification email sent to ${props.email}`);
    sendEmailVerificationLocal(props.email)
      .then((r) => {
        // console.log(r);
        setVerificationCode(r.data.code);
      })
  }

  function validate(value) {
    if (value == verificationCode) {
      setVerifying(false);
      setReadyToVerify(false);
      setVerificationCode("");
      props.onValidate(true);
    }
  }

  return (
    <SFContainer style={{...props.style}}>
      {readyToVerify && (
        <>
          <ValidateButton disabled={verifying} onClick={beginVerification}>
            {verifying ? <FontAwesomeIcon icon={faRotate} spin color={"white"} size={"1x"}/> : "Verify"}
          </ValidateButton>
          <>
            {verifying && (
              <TextField id="outlined-basic" value={props.value} variant="outlined" size="small" onChange={(e) => { validate(e.target.value)}}/>
            )}
          </>
        </>
      )}
    </SFContainer>
    );
};

export {
  ValidatePhone,
  ValidateEmail
};

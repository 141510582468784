import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    user: {},
    name: "",
    email: "",
    uid: "",
    notifications: [],
    roles: [],
  },
  reducers: {
    setCurrentUser: (state, action) => {
          // Redux Toolkit allows us to write "mutating" logic in reducers. It
          // doesn't actually mutate the state because it uses the immer library,
          // which detects changes to a "draft state" and produces a brand new
          // immutable state based off those changes
          state.userRaw = action.payload;
          state.name = action.payload.name;
          state.email = action.payload.email;
          state.uid = action.payload.uid;
          state.notifications = action.payload.notifications;
          state.roles = action.payload.roles;
          state.access = action.payload.access;
        },
  }
});

export const { setCurrentUser } = userSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectUser = (state) => state.user;

export default userSlice.reducer;

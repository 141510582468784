import React, { useEffect, useState } from "react";
import { processStripe, processPaid } from "../../firebase.js";
import { ElementsConsumer, CardElement, PaymentElement } from "@stripe/react-stripe-js";

import styled, { createGlobalStyle, keyframes, css } from "styled-components";

const PayButton = styled.button`
  width: 100%;
  height: 40px;

  margin-top: 30px;

  color: white;
  background-color: #0570de;

  font-family: Open Sans;
  font-size: 18px;
  font-weight: bold;

  border-radius: 8px;
  border: none;

  &:hover {
    transition: .3s;
    background-color: #0570deCC;
  }

  transition: .3s;
`;

const CARD_ELEMENT_OPTIONS = {
  iconStyle: "solid",
  style: {
    base: {
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

function CardSection() {
  return (
    <label>
      Card details
      <CardElement options={CARD_ELEMENT_OPTIONS} />
    </label>
  );
}

// class CheckoutForm extends React.Component {
function CheckoutForm(props) {
  const [isProcessing, setIsProcessing] = useState(false);
  async function handleSubmit(event){
    setIsProcessing(true);
    event.preventDefault();

    // handle payment request
    const { stripe, elements } = props;
    if (!stripe || !elements) {
      return;
    }

    const card = elements.getElement(CardElement);
    const result = await stripe.createToken(card);
    if (result.error) {
      console.log(result.error.message);
    } else {
      processStripe(props.product, result.token.id, props.user)
        .then((r) => {
          if (r.data.paid) {
            processPaid(props.product, props.user);
            props.onProcessed(true);
            setIsProcessing(false);
          }
        })
    }
  };

  return (
    <div>
      <div className="product-info">
        <h3 className="product-title">{props.product.title}</h3>
        <h4 className="product-price">{`$${(props.product.price/100).toString()}`}</h4>
      </div>
      <form onSubmit={handleSubmit}>
        <CardSection />
        <PayButton disabled={isProcessing} id="submit">
          <span id="button-text">
            {isProcessing ? <div className="spinner" id="spinner"></div> : "Pay now"}
          </span>
        </PayButton>
      </form>
    </div>
  );
}

export default CheckoutForm

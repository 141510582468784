import React, { useEffect, useState } from "react";
import MediaQuery from 'react-responsive';
import { Route, Routes, useNavigate, useLocation } from "react-router-dom";
import { auth, logInWithEmailAndPassword, signInWithGoogle, logout, db } from "../firebase";
import { useAuthState } from "react-firebase-hooks/auth";

import { GlassCard, PaperCard, LoFiCard, ReservationCard, OptionCard } from '../components/nuggets/cardset';

import Spacer from "react-spacer";

import { useSelector, useDispatch } from 'react-redux';
import {
  setCurrentUser,
  selectUser,
} from '../userSlice';

import {
  query,
  doc,
  getDocs,
  onSnapshot,
  collection,
  where,
  addDoc,
  setDoc,
  getDoc,
} from "firebase/firestore";

import WebFont from 'webfontloader';

import styled, { createGlobalStyle, keyframes, css } from "styled-components";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faPowerOff,
  faGear,
  faBolt,
  faQuestion
} from "@fortawesome/free-solid-svg-icons";

library.add([faPowerOff, faGear, faBolt, faQuestion]);

const HeaderText = styled.div`
  font-family: Open Sans;
  font-size: 24px;
  font-weight: bold;
  color: black;
`;

const TitleText = styled.div`
  margin: 0px 0px 10px 10px;

  font-family: Open Sans;
  font-size: 36px;
  font-weight: bold;
  color: black;
`;

const BodyText = styled.div`
  margin: 0px 0px 40px 20px;

  font-family: Open Sans;
  font-size: 18px;
  font-weight: bold;
  color: black;
`;

const LFContainer = styled.div`
  margin-top: 20px;
  max-height: calc(100vh - 220px);

  background: #e1e5f1;

  outline: none;
  border: 1px solid #FFFFFF;
  border-radius: 10px;

  display: flex;
  overflow-x: scroll;
  overflow-y: scroll;
`;

function About() {
  const currentUser = useSelector(selectUser);
  const dispatch = useDispatch();

  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {

    WebFont.load({
      google: {
        families: ['Modak', 'Open Sans']
      }
    });

  }, []);

  const sections = [
    <div key="1">
      <TitleText>
        What is DisRe?
      </TitleText>
      <BodyText>
        DisRe is a magical reservation alert system that handles all the stress of finding your perfect reservation.
        DisRe checks for your desired reservation place, date, and time every minute of every hour of every day until the date of your reservation to make sure you never miss an opening!
        <br />
        <br />
        When DisRe finds your reservation slot it will notify you via your preferred notification settings (set up in the Settings page).
        <br />
        <br />
        During our open Beta phase we are offering all tiers at a reduced rate!
      </BodyText>
    </div>,
    <div key="2">
      <TitleText>
        How does it work?
      </TitleText>
      <BodyText>
        1) After registering, go to the Settings page
        <FontAwesomeIcon
          icon={faGear}
          color={"white"}
          aria-hidden={true}
          style={{margin: "0px 5px 0px 5px", height: "20px"}}
        />
        to set up your notification preferences.
        <br />
        <br />
        2) You can provide an email and/or a phone number to receive alerts. Then toggle which method to receive an alert with.
        <br />
        <br />
        3) Once you've set up your notification settings you're ready to set up a reservation alert!
        <br />
        <br />
        4) Click on the blank reservation card to create a new alert. If you have not subscribed you will be shown subscription options to chose from.
        <br />
        <br />
        5) With a valid subscription you can no create a new reservation alert! Select the restaurant, party size, and date + time you'd like the reservation for, and create!
        <br />
        <br />
        Wahoo! Upon creation of your reservation alert DisRe will be searching high and low for your Disney dining reservation and notify you the second it finds an opening!
        <br />
        <br />
      </BodyText>
    </div>
  ]

  return (
    <React.Fragment>
      <MediaQuery minWidth={1000}>
        <LoFiCard style={{position: "absolute", top: "10px", left: "10px", right: "10px", bottom: "10px", zIndex:-1}}/>

        <div style={{margin: "50px", border: "1px solid transparent", overflowY: "scroll"}}>
          <LFContainer>
            <div style={{display: "flex", padding: "20px"}}>
            <HeaderText style={{marginRight: "20px"}}>
              About
            </HeaderText>
            </div>
          </LFContainer>
          <LFContainer style={{paddingTop: "25px", scrollMarginBottom: "25px"}}>
            <div style={{display: "flex", flexDirection: "column", width: "100%", margin: "20px"}}>
              {sections}
            </div>
          </LFContainer>
        </div>
      </MediaQuery>
      <MediaQuery maxWidth={999}>
        <LoFiCard style={{position: "absolute", top: "10px", left: "10px", right: "10px", bottom: "10px", zIndex:-1}}/>

        <div style={{paddingTop: "0px", margin: "5px", border: "1px solid transparent"}}>
          <div style={{display: "flex", padding: "20px"}}>
            <HeaderText style={{marginRight: "20px"}}>
              About
            </HeaderText>
          </div>
          <div style={{height: "calc(100vh - 95px)", overflowY: "scroll"}}>
            {sections}
          </div>
        </div>
      </MediaQuery>
    </React.Fragment>
  );
}
export default About;

import { configureStore } from '@reduxjs/toolkit';
import userReducer from '../userSlice';

import storage from 'redux-persist/lib/storage';

export default configureStore({
  reducer: {
    user: userReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: ['your/action/type'],
        // Ignore these field paths in all actions
        ignoredActionPaths: ['user.userRaw.account.active'],
        // Ignore these paths in the state
        ignoredPaths: ['user.userRaw.account.active'],
      },
    }),
});

import React, { useEffect, useState } from "react";
import MediaQuery from 'react-responsive';
import { processStripe, processPaid } from "../../firebase.js";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { PaymentElement } from "@stripe/react-stripe-js";

import { CouponElement } from './Coupon';

import { PayButton } from './buttonset';

import styled, { createGlobalStyle, keyframes, css } from "styled-components";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faRotate
} from "@fortawesome/free-solid-svg-icons";

library.add([ faRotate]);

// class CheckoutForm extends React.Component {
function CheckoutFormW(props) {
  const stripe = useStripe();
  const elements = useElements();
  const [isProcessing, setIsProcessing] = useState(false);
  const [message, setMessage] = useState(null);

  async function handleSubmit(event){
    event.preventDefault();

    // handle payment request
    if (!stripe || !elements) {
      return;
    }

    window.gtag('event', 'conversion', {
      'send_to': 'AW-1028456482/5SzACKCXgYQYEKKAtOoD',
      'transaction_id': ''
    });

    stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}`,
      },
      redirect: 'if_required',
    })
    .then((r) => {
      if (r.error) {
        setMessage(r.error.message);
      } else {
        if (r.paymentIntent.status == "succeeded") {
          processPaid(props.product, props.user, props.subscription);
          props.onProcessed(true);
        }
      }
    })
    .catch(function(error) {
      setMessage(error.message);
    })

    setIsProcessing(false);
  };

  return (
    <>
      <MediaQuery minWidth={1000}>
        <div>
          <div className="product-info">
            <h3 className="product-title">{props.product.title}</h3>
            <div style={{display: "flex"}} >
              <h4 className="product-price">{`$${(props.amount_due/100).toString()}`}</h4>
              <h4 className="product-price-period">{`/${(props.product.period > 1 ? props.product.period.toString() + " " : "")}day${(props.product.period > 1 ? "s" : "")}`}</h4>
            </div>
          </div>
          <form id="payment-form" onSubmit={handleSubmit}>
            <PaymentElement loader="always"/>
            <CouponElement subscription={props.subscription} applyPromotionCode={props.applyPromotionCode}/>
            <PayButton disabled={isProcessing} id="submit">
              <span id="button-text">
                {isProcessing ? <FontAwesomeIcon icon={faRotate} spin/> : "Pay now"}
              </span>
            </PayButton>

            {message && <div id="payment-message" >{message}</div>}
          </form>
        </div>
      </MediaQuery>
      <MediaQuery maxWidth={999}>
        <div>
          <div className="product-info">
            <h3 className="product-title">{props.product.title}</h3>
            <div style={{display: "flex"}} >
              <h4 className="product-price">{`$${(props.amount_due/100).toString()}`}</h4>
              <h4 className="product-price-period">{`/${(props.product.period > 1 ? props.product.period.toString() + " " : "")}day${(props.product.period > 1 ? "s" : "")}`}</h4>
            </div>
          </div>
          <form id="payment-form" onSubmit={handleSubmit}>
            <PaymentElement loader="always"/>
            <CouponElement subscription={props.subscription} applyPromotionCode={props.applyPromotionCode}/>
            <PayButton disabled={isProcessing} id="submit">
              <span id="button-text">
                {isProcessing ? <FontAwesomeIcon icon={faRotate} spin/> : "Pay now"}
              </span>
            </PayButton>

            {message && <div id="payment-message" >{message}</div>}
          </form>
        </div>
      </MediaQuery>
    </>
  );
}

export default CheckoutFormW

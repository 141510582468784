import React, { useEffect, useState } from "react";
import MediaQuery from 'react-responsive';
import { Route, Routes, useNavigate, useLocation } from "react-router-dom";
import { auth, logInWithEmailAndPassword, signInWithGoogle, logout, db } from "../firebase";
import { createPaymentIntent } from "../firebase.js";
import { useAuthState } from "react-firebase-hooks/auth";

import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import { ElementsConsumer, CardElement } from "@stripe/react-stripe-js";
import CheckoutFormW from '../components/nuggets/CheckoutFormW';

import Spacer from "react-spacer";

import { updateStripeSubscription, processPaid, processAssume, createStripeSubscription, cancelStripeSubscription } from '../firebase';

import { products, REACT_APP_STRIPE_PUBLISHABLE_KEY } from "../constants";

import { useSelector, useDispatch } from 'react-redux';
import {
  setCurrentUser,
  selectUser,
} from '../userSlice';

import {
  query,
  doc,
  getDocs,
  onSnapshot,
  collection,
  where,
  addDoc,
  setDoc,
  getDoc,
} from "firebase/firestore";

import WebFont from 'webfontloader';
import Select from 'react-select';
import { DateTimePicker, Picklist, PicklistOption } from 'react-rainbow-components';
import { matchIsValidTel } from 'mui-tel-input'

import styled, { createGlobalStyle, keyframes, css } from "styled-components";

import { GlassCard, PaperCard, LoFiCard, ReservationCard, OptionCard } from '../components/nuggets/cardset';
import { GlassButton, PaperButton, BasicButton, PayButton } from '../components/nuggets/buttonset';
import { restaurantmap } from '../dataset.js'
import { Modal, FakeModal, OptionModal, PaymentModal } from '../components/nuggets/Modal';
import { SettingsField, SettingsContainer, SettingsGroup } from '../components/nuggets/settingsfield';
import { ValidatePhone, ValidateEmail } from '../components/nuggets/validate';
// import "../css/bgstyles.css";

import { Ring } from '@uiball/loaders'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faCalendar,
  faCheck,
  faRotate,
  faArrowRightLong
} from "@fortawesome/free-solid-svg-icons";

library.add([faCalendar, faCheck, faRotate, faArrowRightLong]);

const HeaderText = styled.div`
  font-family: Open Sans;
  font-size: 24px;
  font-weight: bold;
  color: black;
`;

const LFContainer = styled.div`
  margin-top: 20px;
  max-height: calc(100vh - 220px);

  background: #e1e5f1;

  outline: none;
  border: 1px solid #FFFFFF;
  border-radius: 10px;

  display: flex;
  overflow-x: scroll;
  overflow-y: scroll;
`;

const UpdateButton = styled.button`
  width: 100%;
  height: 40px;

  margin-top: 15px;

  color: white;
  background-color: #0570de;

  font-family: Open Sans;
  font-size: 18px;
  font-weight: bold;

  border-radius: 8px;
  border: none;

  &:hover {
    transition: .3s;
    background-color: #0570deCC;
  }

  transition: .3s;
`;

function Settings() {
  const currentUser = useSelector(selectUser);
  const dispatch = useDispatch();

  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [emailValidated, setEmailValidated] = useState(false);
  const [phone, setPhone] = useState("");
  const [phoneValidated, setPhoneValidated] = useState(false);
  const [textNotification, setTextNotification] = useState(false);
  const [emailNotification, setEmailNotification] = useState(false);

  const [product, setProduct] = useState(null);
  const [currentProduct, setCurrentProduct] = useState(null);
  const [subscription, setSubscription] = useState(null);
  const [currentSubscription, setCurrentSubscription] = useState(null);
  const [promptOptionsModal, setPromptOptionsModal] = useState(false);
  const [promptPaymentModal, setPromptPaymentModal] = useState(false);

  const [isProcessing, setIsProcessing] = useState(false);
  const [resubscribing, setResubscribing] = useState(false);
  const [message, setMessage] = useState(null);

  const stripePromise = loadStripe(REACT_APP_STRIPE_PUBLISHABLE_KEY);
  const [clientSecret, setClientSecret] = useState(null);

  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      return;
    }

    if (user) {

    }
  }, [user, loading]);

  useEffect(() => {

    WebFont.load({
      google: {
        families: ['Modak', 'Open Sans']
      }
    });

  }, []);

  function updateUser(){
    let validPhone = phone;
    let validEmail = email;
    if (!phoneValidated) {
      validPhone = currentUser.userRaw.phone;
    }
    if (!emailValidated) {
      validEmail = currentUser.userRaw.email;
    }
    setDoc(doc(db, "users", currentUser.uid), {
      name: firstName + " " + lastName,
      phone: validPhone,
      email: validEmail,
      notificationmethod: {
        email: emailNotification,
        text: textNotification
      }
    }, {merge: true}).then((docRef) => {

    });
  }

  function updatePhone(value, info) {
    setPhone(value);
    if (value != "" && value != null && value == currentUser.userRaw.phone){
      setPhoneValidated(true);
    } else {
      setPhoneValidated(false);
    }
  }

  function updateEmail(value){
    setEmail(value);
    if (value != "" && value != null && value == currentUser.userRaw.email){
      setEmailValidated(true);
    } else {
      setEmailValidated(false);
    }
  }

  function denull(value){
    if (value == null) {
      return "";
    }

    return value;
  }

  function handleSubscription(type) {
    if (type == "magic"){
      setPromptOptionsModal(false);
      setProduct(products.magic)
      setPromptPaymentModal(true);
    } else if (type == "deluxe") {
      setPromptOptionsModal(false);
      setProduct(products.deluxe)
      setPromptPaymentModal(true);
    } else if (type == "flex") {
      setPromptOptionsModal(false);
      setProduct(products.flex)
      setPromptPaymentModal(true);
    }
  };

  useEffect(() => {
    if (product != null && currentSubscription == null) {
      createStripeSubscription(product, currentUser.userRaw.customerID)
      .then((r) => {
        // console.log(r);
        setSubscription(r.data.id)
        setClientSecret(r.data.latest_invoice.payment_intent.client_secret);
      })
      // createPaymentIntent(product, currentUser)
      // .then((r) => {
      //   console.log(r);
      //   setClientSecret(r.data.client_secret);
      // })
    }
  }, [product]);

  function modifySubscription() {
    setIsProcessing(true);
    setResubscribing(true);
    updateStripeSubscription(product, currentSubscription, currentUser.userRaw.customerID)
    .then((r) => {
      if (r.error) {
        setMessage(r.error.message);
      } else {
        // console.log(r);
        // console.log(r.data.plan.metadata.type);
        // processPaid({type: r.data.plan.metadata.type}, currentUser, r.data.id);
        processAssume({type: r.data.plan.metadata.type, status: "active"}, currentUser);
        processed();
      }
    })
    .catch(function(error) {
      setMessage(error.message);
    })
  }

  function processed() {
    setProduct(null);
    setIsProcessing(false);
    setPromptPaymentModal(false);
    setResubscribing(false);
  }

  function cancelSubscription() {
    setIsProcessing(true);
    cancelStripeSubscription(product, currentSubscription, false)
    .then((r) => {
      if (r.error) {
        setMessage(r.error.message);
      } else {
        processAssume({type: r.data.plan.metadata.type, status: "canceling"}, currentUser);
        processed();
      }
    })
    .catch(function(error) {
      setMessage(error.message);
    })
  }

  useEffect(() => {
    if (currentUser.uid != "") {
      setFirstName(denull(currentUser.name.split(" ")[0]));
      setLastName(denull(currentUser.name.split(" ")[1]));
      setEmail(denull(currentUser.userRaw.email));
      setPhone(denull(currentUser.userRaw.phone));
      setTextNotification(currentUser.userRaw.notificationmethod.text);
      setEmailNotification(currentUser.userRaw.notificationmethod.email);
      setCurrentSubscription(currentUser.userRaw.account.subscription)
      setCurrentProduct(products[currentUser.userRaw.account.type]);

      if (currentUser.userRaw.email != null && currentUser.userRaw.email != ""){
        setEmailValidated(true);
      }
      if (currentUser.userRaw.phone != null && currentUser.userRaw.phone != ""){
        setPhoneValidated(true);
      }
    }
  }, [currentUser]);

  return (
    <React.Fragment>
      <MediaQuery minWidth={1000}>
        <LoFiCard style={{position: "absolute", top: "10px", left: "10px", right: "10px", bottom: "10px", zIndex:-1}}/>

        <div style={{margin: "50px", border: "1px solid transparent", overflowY: "scroll"}}>
          <LFContainer>
            <div style={{display: "flex", padding: "20px"}}>
            <HeaderText style={{marginRight: "20px"}}>
              Settings
            </HeaderText>
            </div>
          </LFContainer>
          <LFContainer style={{paddingTop: "25px", scrollMarginBottom: "25px"}}>
            <div style={{display: "flex", flexDirection: "column", width: "100%", margin: "0px 20px"}}>
            {currentUser.uid != "" && (
              <div style={{display: "flex", flexDirection: "row"}}>
                <SettingsContainer title="Profile">
                  <SettingsField field="First Name" type="text" value={firstName} onChange={(e) => { setFirstName(e.target.value)}}/>
                  <SettingsField field="Last Name" type="text" value={lastName} onChange={(e) => { setLastName(e.target.value)}}/>
                  <div style={{display: "flex", flexDirection: "row", alignItems:"center"}}>
                    <SettingsField field="Email" type="email" value={email} style={{width: "50%"}} onChange={(e) => { updateEmail(e.target.value)}}/>
                    {!emailValidated && (
                      <ValidateEmail email={email} onValidate={setEmailValidated}/>
                    )}
                    {emailValidated && (
                      <FontAwesomeIcon icon={faCheck} color={"green"} size={"1x"} style={{alignItems: "center", marginTop: "15px", marginLeft: "10px"}}/>
                    )}
                  </div>
                  <div style={{display: "flex", flexDirection: "row", alignItems:"center"}}>
                    <SettingsField field="Phone Number" type="phone" value={phone} onChange={updatePhone}/>
                    {!phoneValidated && (
                      <ValidatePhone phone={phone} onValidate={setPhoneValidated}/>
                    )}
                    {phoneValidated && (
                      <FontAwesomeIcon icon={faCheck} color={"green"} size={"1x"} style={{alignItems: "center", marginTop: "15px", marginLeft: "10px"}}/>
                    )}
                  </div>
                </SettingsContainer>
                <SettingsGroup style={{display: "flex", flexDirection:"column"}}>
                  <SettingsContainer title="Notifications" style={{width: "100%"}}>
                    <SettingsField field="Phone" type="toggle" value={textNotification} disabled={!phoneValidated || phone == ""} onChange={setTextNotification}/>
                    <SettingsField field="Email" type="toggle" value={emailNotification} disabled={!emailValidated || email == ""} onChange={setEmailNotification}/>
                  </SettingsContainer>
                  {currentUser.userRaw.authProvider == "email" &&
                    <SettingsContainer title="Password" style={{width: "100%"}}>
                      <SettingsField field="Current Password" type="password" value={""} />
                      <SettingsField field="New Password" type="password" value={""} />
                      <SettingsField field="Confirm Password" type="password" value={""} />
                    </SettingsContainer>
                  }
                  <SettingsContainer title="Plan" style={{width: "100%"}}>
                    <SettingsField field="Current Plan" type="plan" product={currentProduct} account={currentUser.userRaw.account} modify={() => setPromptOptionsModal(true)} cancel={cancelSubscription}/>
                    {(currentUser.userRaw.account.status != "active") && (
                      `This subscription is ${currentUser.userRaw.account.status}${currentUser.userRaw.account.status == "canceling" ? ", subscription will end on..." : "."}`
                    )}
                  </SettingsContainer>

                </SettingsGroup>
              </div>
            )}

            <UpdateButton onClick={updateUser}> Update </UpdateButton>
            <div style={{color: "#00000000"}}> . </div>
            </div>
          </LFContainer>
        </div>
        {promptOptionsModal &&
          <OptionModal title={"Select a plan"} close={() => setPromptOptionsModal(false)}>
            <div style={{ width: "80vw", "fontFamily":"Open Sans", "fontSize": "16px", color: "black" }}>
              <div style={{display: "flex", flexDirection: "row"}}>
                <OptionCard
                  title={"Flex plan"}
                  price={"$1.99"}
                  timeframe={"/ per alert / per day"}
                  content={["Pay as you go!"]}
                  footer={<PaperButton title={"Select"} style={{width: "60%"}} onClick={()=>handleSubscription("flex")} disabled={currentUser.userRaw.account.type == "flex"}/>}
                  selectable
                  emboss
                  key={"Flex"}
                  disabled={currentUser.userRaw.account.type == "flex"}
                />
                <OptionCard
                  title={"Deluxe plan"}
                  price={"$19.99"}
                  timeframe={"/ per 30 days"}
                  content={["5 Active Alerts", "Priority Alerts"]}
                  footer={<PaperButton title={"Select"} style={{width: "60%"}} onClick={()=>handleSubscription("deluxe")} disabled={currentUser.userRaw.account.type == "deluxe"}/>}
                  selectable
                  emboss
                  key={"Deluxe"}
                  disabled={currentUser.userRaw.account.type == "deluxe"}
                />
                <OptionCard
                  title={"Magic plan"}
                  price={"$29.99"}
                  timeframe={"/ per 30 days"}
                  content={["Unlimited Active Alerts", "Skip the Line Alerts"]}
                  footer={<PaperButton title={"Select"} style={{width: "60%"}} onClick={()=>handleSubscription("magic")} disabled={currentUser.userRaw.account.type == "magic"}/>}
                  selectable
                  emboss
                  key={"Magic"}
                  disabled={currentUser.userRaw.account.type == "magic"}
                />
              </div>
            </div>
          </OptionModal>
        }
        {(promptPaymentModal && product && currentSubscription!=null) &&
          <PaymentModal title={"Checkout"} style={{color: "black"}} close={() => setPromptPaymentModal(false)}>
            <div style={{"fontFamily":"Open Sans", "fontSize": "16px"}}>
              <div className="product-info">
                <div style={{display: "flex", flexDirection: "row"}}>
                  <div>
                    <h3 className="product-title">{currentProduct.title}</h3>
                    <div style={{display: "flex"}} >
                      <h4 className="product-price">{`$${(currentProduct.price/100).toString()}`}</h4>
                      <h4 className="product-price-period">{`/${(currentProduct.period > 1 ? currentProduct.period.toString() + " " : "")}day${(currentProduct.period > 1 ? "s" : "")}`}</h4>
                    </div>
                  </div>
                  <div style={{display: "flex", alignItems: "center", margin: "40px"}}>
                    <FontAwesomeIcon icon={faArrowRightLong} size={"2x"} beat/>
                  </div>
                  <div>
                    <h3 className="product-title">{product.title}</h3>
                    <div style={{display: "flex"}} >
                      <h4 className="product-price">{`$${(product.price/100).toString()}`}</h4>
                      <h4 className="product-price-period">{`/${(product.period > 1 ? product.period.toString() + " " : "")}day${(product.period > 1 ? "s" : "")}`}</h4>
                    </div>
                  </div>
                </div>
                <PayButton disabled={isProcessing} onClick={modifySubscription}>
                  <span id="button-text">
                    {isProcessing ? <FontAwesomeIcon icon={faRotate} spin/> : "Modify Subscription"}
                  </span>
                </PayButton>
              </div>
            </div>
          </PaymentModal>
        }
        {(promptPaymentModal && currentSubscription==null && !resubscribing) &&
          <PaymentModal title={"Checkout"} style={{color: "black"}} close={() => setPromptPaymentModal(false)}>

            <div style={{ width: "50vw", "fontFamily":"Open Sans", "fontSize": "16px"}}>
              {stripePromise && clientSecret && (
                <Elements stripe={stripePromise} options={{clientSecret}} loader="always">
                  <CheckoutFormW product={product} user={currentUser} onProcessed={processed} subscription={subscription}/>
                </Elements>
              )}
              {!stripePromise || !clientSecret && (
                <div style={{display: "flex", justifyContent: "center"}}>
                  <Ring
                   size={40}
                   lineWeight={5}
                   speed={2}
                   color="black"
                  />
                </div>
              )}
            </div>
          </PaymentModal>
        }
      </MediaQuery>
      <MediaQuery maxWidth={999}>
        <LoFiCard style={{position: "absolute", top: "10px", left: "10px", right: "10px", bottom: "10px", zIndex:-1}}/>

        <div style={{paddingTop: "0px", margin: "5px", border: "1px solid transparent"}}>
          <div style={{display: "flex", padding: "20px"}}>
            <HeaderText style={{marginRight: "20px"}}>
              Settings
            </HeaderText>
          </div>
            {currentUser.uid != "" && (
              <div style={{height: `75vh`, marginBottom: "0px", overflowX: "scroll"}}>
                <SettingsContainer title="Profile">
                  <SettingsField field="First Name" type="text" test={""} value={firstName} onChange={(e) => { setFirstName(e.target.value)}}/>
                  <SettingsField field="Last Name" type="text" value={lastName} onChange={(e) => { setLastName(e.target.value)}}/>
                  <div style={{display: "flex", flexDirection: "row", alignItems:"center"}}>
                    <SettingsField field="Email" type="email" value={email} style={{width: "100%"}} onChange={(e) => { updateEmail(e.target.value)}}/>
                    {!emailValidated && (
                      <ValidateEmail email={email} onValidate={setEmailValidated}/>
                    )}
                    {emailValidated && (
                      <FontAwesomeIcon icon={faCheck} color={"green"} size={"1x"} style={{alignItems: "center", marginTop: "15px", marginLeft: "10px"}}/>
                    )}
                  </div>
                  <div style={{display: "flex", flexDirection: "row", alignItems:"center"}}>
                    <SettingsField field="Phone Number" type="phone" value={phone} onChange={updatePhone}/>
                    {!phoneValidated && (
                      <ValidatePhone phone={phone} onValidate={setPhoneValidated}/>
                    )}
                    {phoneValidated && (
                      <FontAwesomeIcon icon={faCheck} color={"green"} size={"1x"} style={{alignItems: "center", marginTop: "15px", marginLeft: "10px"}}/>
                    )}
                  </div>
                </SettingsContainer>
                  <SettingsContainer title="Notifications" style={{width: "100%"}}>
                    <SettingsField field="Phone" type="toggle" value={textNotification} disabled={!phoneValidated && phone == ""} onChange={setTextNotification}/>
                    <SettingsField field="Email" type="toggle" value={emailNotification} disabled={!emailValidated && email == ""} onChange={setEmailNotification}/>
                  </SettingsContainer>
                  {currentUser.userRaw.authProvider == "email" &&
                    <SettingsContainer title="Password" style={{width: "100%"}}>
                      <SettingsField field="Current Password" type="password" value={""} />
                      <SettingsField field="New Password" type="password" value={""} />
                      <SettingsField field="Confirm Password" type="password" value={""} />
                    </SettingsContainer>
                  }
                  <SettingsContainer title="Plan" style={{width: "100%"}}>
                    <SettingsField field="Current Plan" type="plan" product={currentProduct} account={currentUser.userRaw.account} modify={() => setPromptOptionsModal(true)} cancel={cancelSubscription}/>
                    {(currentUser.userRaw.account.status != "active") && (
                      `This subscription is ${currentUser.userRaw.account.status}${currentUser.userRaw.account.status == "canceling" ? ", subscription will end on..." : "."}`
                    )}
                  </SettingsContainer>
              </div>
            )}
          <UpdateButton onClick={updateUser}> Update </UpdateButton>
        </div>
        {promptOptionsModal &&
          <OptionModal title={"Select a plan"} close={() => setPromptOptionsModal(false)}>
            <div style={{ width: "80vw", "fontFamily":"Open Sans", "fontSize": "16px", color: "black" }}>
              <div style={{display: "flex", flexDirection: "column"}}>
                <OptionCard
                  title={"Flex plan"}
                  price={"$1.99"}
                  timeframe={"/ per alert / per day"}
                  content={["Pay as you go!"]}
                  selectable
                  emboss
                  onClick={()=>handleSubscription("flex")}
                  key={"Flex"}
                  disabled={currentUser.userRaw.account.type == "flex"}
                />
                <OptionCard
                  title={"Deluxe plan"}
                  price={"$19.99"}
                  timeframe={"/ per 30 days"}
                  content={["5 Active Alerts", "Priority Alerts"]}
                  selectable
                  emboss
                  onClick={()=>handleSubscription("deluxe")}
                  key={"Deluxe"}
                  disabled={currentUser.userRaw.account.type == "deluxe"}
                />
                <OptionCard
                  title={"Magic plan"}
                  price={"$29.99"}
                  timeframe={"/ per 30 days"}
                  content={["Unlimited Active Alerts", "Skip the Line Alerts"]}
                  selectable
                  emboss
                  onClick={()=>handleSubscription("magic")}
                  key={"Magic"}
                  disabled={currentUser.userRaw.account.type == "magic"}
                />
              </div>
            </div>
          </OptionModal>
        }
        {(promptPaymentModal && product && currentSubscription!=null) &&
          <PaymentModal title={"Checkout"} style={{color: "black"}} close={() => setPromptPaymentModal(false)}>
            <div style={{"fontFamily":"Open Sans", "fontSize": "16px"}}>
              <div className="product-info">
                <div style={{display: "flex", flexDirection: "row"}}>
                  <div>
                    <h3 className="product-title">{currentProduct.title}</h3>
                    <div style={{display: "flex"}} >
                      <h4 className="product-price">{`$${(currentProduct.price/100).toString()}`}</h4>
                      <h4 className="product-price-period">{`/${(currentProduct.period > 1 ? currentProduct.period.toString() + " " : "")}day${(currentProduct.period > 1 ? "s" : "")}`}</h4>
                    </div>
                  </div>
                  <div style={{display: "flex", alignItems: "center", margin: "40px"}}>
                    <FontAwesomeIcon icon={faArrowRightLong} size={"2x"} beat/>
                  </div>
                  <div>
                    <h3 className="product-title">{product.title}</h3>
                    <div style={{display: "flex"}} >
                      <h4 className="product-price">{`$${(product.price/100).toString()}`}</h4>
                      <h4 className="product-price-period">{`/${(product.period > 1 ? product.period.toString() + " " : "")}day${(product.period > 1 ? "s" : "")}`}</h4>
                    </div>
                  </div>
                </div>
                <PayButton disabled={isProcessing} onClick={modifySubscription}>
                  <span id="button-text">
                    {isProcessing ? <FontAwesomeIcon icon={faRotate} spin/> : "Modify Subscription"}
                  </span>
                </PayButton>
              </div>
            </div>
          </PaymentModal>
        }
        {(promptPaymentModal && currentSubscription==null && !resubscribing) &&
          <PaymentModal title={"Checkout"} style={{color: "black"}} close={() => setPromptPaymentModal(false)}>

            <div style={{ width: "50vw", "fontFamily":"Open Sans", "fontSize": "16px"}}>
              {stripePromise && clientSecret && (
                <Elements stripe={stripePromise} options={{clientSecret}} loader="always">
                  <CheckoutFormW product={product} user={currentUser} onProcessed={processed} subscription={subscription}/>
                </Elements>
              )}
              {!stripePromise || !clientSecret && (
                <div style={{display: "flex", justifyContent: "center"}}>
                  <Ring
                   size={40}
                   lineWeight={5}
                   speed={2}
                   color="black"
                  />
                </div>
              )}
            </div>
          </PaymentModal>
        }
      </MediaQuery>
    </React.Fragment>
  );
}
export default Settings;

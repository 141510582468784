import React from "react";
import ReactDOM from "react-dom";

import styled, { createGlobalStyle, keyframes, css } from "styled-components";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faPizzaSlice,
  faPepperHot,
  faLemon,
  faIceCream,
  faHotdog,
  faHamburger,
  faCalendar,
} from "@fortawesome/free-solid-svg-icons";

import {
  faCircleCheck
} from "@fortawesome/free-regular-svg-icons";

const iconLib = [ faPizzaSlice, faPepperHot, faLemon, faIceCream, faHotdog, faHamburger ];
library.add([...iconLib, faCalendar]);

const animate = keyframes`
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
`;

const animaterev = keyframes`
  0% {
    transform: translateX(-95%);
  }
  100% {
    transform: translateX(5%);
  }
`;

const BGHost = styled.div`
  position: absolute;
  // transform: rotate(-30deg);
  // -webkit-transform:rotate(-30deg);

  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
`;

const BGRow = styled.div`
  position: relative;
  width: 100%;

  display: flex;
  flexDirection: row:

  overflow: nowrap;
  animation: ${animate} 80s linear infinite;
  animationdelay: -80s;

  &:nth-child(even) {
    animation: ${animaterev} 80s linear infinite;
    animationdelay: -40s;
  }
`;

const BGSubRow = styled.div`
  position: relative;

  display: flex;
  flexDirection: row:
`;

const SFAI = styled(FontAwesomeIcon)`
  position: relative;

  color: #53789510;
  transition: 1s;
  cursor: default;
  user-select: none;
  margin: 15px;

  width: 80px;
  height: 80px;

  &:hover {
    color: #FFFFFF;
    transition: 0s;
  };
`;

const HM = styled.img`
  position: relative;

  fill: #53789510;
  transition: 1s;
  cursor: default;
  user-select: none;
  margin: 15px;

  width: 80px;
  height: 80px;

  &:hover {
    color: #FFFFFF;
    transition: 0s;
  };
`;

function JBBack(props) {
  const hiddenM = [Math.floor(Math.random() * 10), Math.floor(Math.random() * 30)];

  const style = props.interactive ? null : {zIndex: -1000};
  let size = "80px";
  if (props.size) {
    size = `${props.size}px`;
  }

  return (
    <BGHost style={style}>
      {[...Array(10)].map((x, i) => {
        return (
          <BGRow key={i + "Row"}>
            <BGSubRow key={i+ "SubRow"}>
              {[...Array(30)].map((x, j) => {
                return (hiddenM[0] == i && hiddenM[1] == j ?
                  <HM key={(i+1)*(j+1)} src={require('../../assets/mouse.svg').default} style={{width: size, height: size}} alt='hiddenM' /> :
                  <SFAI key={(i+1)*(j+1)} style={{color: (props.colorize ? Math.random().toString(16).substr(-6) : null), width: size, height: size}} icon={iconLib[Math.floor((Math.random()*iconLib.length))]} aria-hidden={true} />
                )
              })}
            </BGSubRow>
          </BGRow>
        )
      })}
    </BGHost>
  )
};

export {
  JBBack
};

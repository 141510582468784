import React from "react";
import MediaQuery from 'react-responsive';
import { Route, Routes, useNavigate, useLocation, NavLink, Link } from "react-router-dom";

import { Helmet } from "react-helmet";
import favi from './assets/favicon/favicon.png';
import TagManager from 'react-gtm-module';

import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db, rtdb, logout } from "./firebase";
import { getDatabase, ref, set, onValue } from "firebase/database";
import { query, collection, getDocs, where, onSnapshot, doc } from "firebase/firestore";

import { useSelector, useDispatch } from 'react-redux';
import {
  setCurrentUser,
  selectUser,
} from './userSlice';

import { JBBack } from "./components/nuggets/JBBack";
import Home from "./views/Home";
import routes from "./routes";

import { GlassCard, PaperCard } from './components/nuggets/cardset';
import { GlassButton } from './components/nuggets/buttonset';
import { MenuItem } from './components/nuggets/menuitem';

import { BackgroundColorContext } from "./contexts/BackgroundColorContext";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faPowerOff,
  faGear,
  faBolt,
  faQuestion
} from "@fortawesome/free-solid-svg-icons";
import {
  faReadme
} from "@fortawesome/free-brands-svg-icons";

library.add([faPowerOff, faGear, faBolt, faQuestion, faReadme]);

const tagManagerArgs = {
  gtmId: 'G-1MYJQ09DTH'
}
TagManager.initialize(tagManagerArgs)

var ps;

const textHeader = {
  fontFamily: 'Montserrat Alternates, Extrabold',
  fontSize: "32px",
  // color: "black",

  background: "linear-gradient(to right, #30CFD0 0%, #330867 100%)",
	WebkitBackgroundClip: "text",
	WebkitTextFillColor: "transparent",
}

function RouteHandler(props) {
  const currentUser = useSelector(selectUser);
  const dispatch = useDispatch();

  const [user, loading, error] = useAuthState(auth);
  const location = useLocation();

  const navigate = useNavigate();

  React.useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      return;
    }
    if (user) {
      // const unsubscribe = onSnapshot(doc(db, "users", user.uid), (doc) => {
      //   dispatch(setCurrentUser(doc.data()));
      // });
      // //remember to unsubscribe from your realtime listener on unmount or you will create a memory leak
      // return () => unsubscribe()
    } else {
      navigate("/")
    }
  }, [user, loading]);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      let path = "";
      if (prop.layout === "/main") {
        return (
          <Route
            path={prop.path}
            element={<prop.component />}
            key={key}
          />
        )
      } else {
        return null;
      }
    });
  };

  document.body.style.overflow = "hidden";
  window.dataLayer.push({
    event: 'pageview'
  });

  return (
    <BackgroundColorContext.Consumer>
      {({ color, changeColor }) => (
        <React.Fragment>
          <div className="wrapper">
            <div className="main-panel" data={color}>
              <Routes>
                {getRoutes(routes)}
                <Route path="*" element={<Home />} />
              </Routes>
              <MediaQuery minWidth={1000}>
                <div style={{position: "absolute", top: "30px", right: "30px", minHeight: "20px", display: "flex"}}>
                  <FontAwesomeIcon
                    icon={faBolt}
                    color={location.pathname == "/main" ? "yellow" : "white"}
                    aria-hidden={true}
                    style={{marginLeft: "12px", height: "20px"}}
                    component={Link}
                    onClick={() => navigate("/main")}
                  />
                  <FontAwesomeIcon
                    icon={faGear}
                    color={location.pathname == "/settings" ? "gray" : "white"}
                    aria-hidden={true}
                    style={{marginLeft: "12px", height: "20px"}}
                    component={Link}
                    onClick={() => navigate("/settings")}
                  />
                  <FontAwesomeIcon
                    icon={faReadme}
                    color={location.pathname == "/about" ? "blue" : "white"}
                    aria-hidden={true}
                    style={{marginLeft: "12px", height: "20px"}}
                    component={Link}
                    onClick={() => navigate("/about")}
                  />
                  <FontAwesomeIcon
                    icon={faPowerOff}
                    color={"white"}
                    aria-hidden={true}
                    onClick={logout}
                    style={{marginLeft: "12px", height: "20px"}}
                  />
                </div>
              </MediaQuery>
              <MediaQuery maxWidth={999}>
                <div style={{position: "absolute", top: "30px", right: "30px", minHeight: "20px", display: "flex"}}>
                  <FontAwesomeIcon
                    icon={faBolt}
                    color={location.pathname == "/main" ? "yellow" : "white"}
                    aria-hidden={true}
                    style={{marginLeft: "24px", height: "30px"}}
                    component={Link}
                    onClick={() => navigate("/main")}
                  />
                  <FontAwesomeIcon
                    icon={faGear}
                    color={location.pathname == "/settings" ? "gray" : "white"}
                    aria-hidden={true}
                    style={{marginLeft: "24px", height: "30px"}}
                    component={Link}
                    onClick={() => navigate("/settings")}
                  />
                  <FontAwesomeIcon
                    icon={faReadme}
                    color={location.pathname == "/about" ? "blue" : "white"}
                    aria-hidden={true}
                    style={{marginLeft: "24px", height: "30px"}}
                    component={Link}
                    onClick={() => navigate("/about")}
                  />
                  <FontAwesomeIcon
                    icon={faPowerOff}
                    color={"white"}
                    aria-hidden={true}
                    onClick={logout}
                    style={{marginLeft: "24px", height: "30px"}}
                  />
                </div>
              </MediaQuery>
            </div>
          </div>
          <MediaQuery minWidth={1000}>
            <JBBack />
          </MediaQuery>
          <MediaQuery maxWidth={999}>
            <JBBack size={45} />
          </MediaQuery>
        </React.Fragment>
      )}
    </BackgroundColorContext.Consumer>
  );
}

export default RouteHandler;

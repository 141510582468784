import React, { useEffect, useState } from "react";

import {useStripe, useElements, PaymentElement} from '@stripe/react-stripe-js';

import styled, { createGlobalStyle, keyframes, css } from "styled-components";

import { GlassCard, PaperCard, LoFiCard, ReservationCard, OptionCard } from './cardset';
import { GlassButton, PaperButton, BasicButton } from './buttonset';
import { Modal, FakeModal } from './Modal';
// import "../css/bgstyles.css";


function SubscriptionOptions() {
  const stripe = useStripe();
  const elements = useElements();

  async function handleSubscription(event) {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: "https://example.com/order/123/complete",
      },
    });

    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
      console.log(result.error.message);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  return (
    <React.Fragment>
      <div style={{ width: "80vw", "fontFamily":"Open Sans", "fontSize": "16px", color: "white" }}>
        <div style={{display: "flex", flexDirection: "row"}}>
          <OptionCard title={"Basic plan"} price={"$1.99"} timeframe={"/ per alert / per day"} content={["Pay as you go!"]} footer={<> <PaymentElement /> <PaperButton title={"Purchase"} style={{width: "60%"}} onClick={handleSubscription} disabled={!stripe}/></>} selectable emboss/>
          <OptionCard title={"Deluxe plan"} price={"$19.99"} timeframe={"/ per 30 days"} content={["5 Active Alerts", "Priority Alerts"]} selectable emboss/>
          <OptionCard title={"Magic plan"} price={"$29.99"} timeframe={"/ per 30 days"} content={["Unlimited Active Alerts", "Skip the Line Alerts"]} selectable emboss/>
        </div>
      </div>
    </React.Fragment>
  );
}
export default SubscriptionOptions;

export const restaurantmap = {
  "California Grill": "90001336;entityType=restaurant",
  "The BOATHOUSE": "17936197;entityType=restaurant",
  "Tiffins Restaurant": "18069632;entityType=restaurant",
  "Whispering Canyon Cafe": "90002376;entityType=restaurant",
  "50's Prime Time Café": "90001216;entityType=restaurant",
  "Akershus Royal Banquet Hall": "90002066;entityType=restaurant",
  "Ale & Compass Restaurant": "18733814;entityType=restaurant",
  "Amare": "19629076;entityType=restaurant",
  "Be Our Guest Restaurant": "16660079;entityType=restaurant",
  "Beaches & Cream Soda Shop": "90001272;entityType=restaurant",
  "Biergarten Restaurant": "90001292;entityType=restaurant",
  "Boatwright's Dining Hall": "90002448;entityType=restaurant",
  "Boma - Flavors of Africa": "90001320;entityType=restaurant",
  "Cape May Cafe": "90001347;entityType=restaurant",
  "Chef Art Smith's Homecomin'": "18427314;entityType=restaurant",
  "Chef Mickey's": "90001369;entityType=restaurant",
  "Chefs de France": "90001373;entityType=restaurant",
  "Cinderella's Royal Table": "90002464;entityType=restaurant",
  "Cítricos": "90001378;entityType=restaurant",
  "City Works Eatery & Pour House": "19124370;entityType=restaurant",
  "Coral Reef Restaurant": "90001416;entityType=restaurant",
  "The Crystal Palace": "90002660;entityType=restaurant",
  "The Diamond Horseshoe": "247904;entityType=restaurant",
  "The Dining Room at Victoria & Albert’s": "90002336;entityType=restaurant",
  "The Edison": "18472966;entityType=restaurant",
  "Enzo's Hideaway": "18808553;entityType=restaurant",
  "Flying Fish": "90001548;entityType=restaurant",
  "Frontera Cocina": "18436515;entityType=restaurant",
  "The Garden Grill Restaurant": "90002237;entityType=restaurant",
  "Grand Floridian Cafe": "90001724;entityType=restaurant",
  "Hollywood & Vine": "90001744;entityType=restaurant",
  "The Hollywood Brown Derby": "90002245;entityType=restaurant",
  "Hoop-Dee-Doo Musical Revue": "80010856;entityType=Dinner-Show",
  "House of Blues® Restaurant": "90001754;entityType=restaurant",
  "Il Mulino": "90002012;entityType=restaurant",
  "Jaleo by José Andrés": "19063652;entityType=restaurant",
  "Jiko - The Cooking Place": "90001778;entityType=restaurant",
  "Jungle Navigation Co. LTD Skipper Canteen": "18185631;entityType=restaurant",
  "Kona Cafe": "90001786;entityType=restaurant",
  "La Cava Experience": "19629094;entityType=Dining-Event",
  "La Crêperie de Paris": "19511274;entityType=restaurant",
  "La Hacienda de San Angel": "15525573;entityType=restaurant",
  "Le Cellier Steakhouse": "90001798;entityType=restaurant",
  "Liberty Tree Tavern": "90001819;entityType=restaurant",
  "Mama Melrose's Ristorante Italiano": "90001865;entityType=restaurant",
  "Maria & Enzo's Ristorante": "18807537;entityType=restaurant",
  "Maya Grill": "90001879;entityType=restaurant",
  "Monsieur Paul": "90001296;entityType=restaurant",
  "Morimoto Asia": "17936194;entityType=restaurant",
  "Nine Dragons Restaurant": "90001985;entityType=restaurant",
  "Oga's Cantina at the Walt Disney World Resort": "19267226;entityType=restaurant",
  "'Ohana": "90002606;entityType=restaurant",
  "Olivia's Cafe": "90002006;entityType=restaurant",
  "Paddlefish": "18457125;entityType=restaurant",
  "Paradiso 37, Taste of the Americas": "343233;entityType=restaurant",
  "Planet Hollywood": "18475350;entityType=restaurant",
  "The Plaza Restaurant": "90002670;entityType=restaurant",
  "Raglan Road™ Irish Pub and Restaurant": "90002044;entityType=restaurant",
  "Rainforest Cafe® at Disney Springs Marketplace": "90001873;entityType=restaurant",
  "Rainforest Cafe® at Disney's Animal Kingdom": "90002440;entityType=restaurant",
  "Rix Sports Bar & Grill": "248573;entityType=restaurant",
  "Rose & Crown Dining Room": "90002084;entityType=restaurant",
  "San Angel Inn Restaurante": "90002100;entityType=restaurant",
  "Sanaa": "293704;entityType=restaurant",
  "Sangria University": "19633114;entityType=Dining-Event",
  "Sci-Fi Dine-In Theater Restaurant": "90002114;entityType=restaurant",
  "Sebastian's Bistro": "19069003;entityType=restaurant",
  "Shula's Steak House": "90002122;entityType=restaurant",
  "Space 220 Lounge": "19634138;entityType=restaurant",
  "Space 220 Restaurant": "19433706;entityType=restaurant",
  "Spice Road Table": "17720675;entityType=restaurant",
  "Splitsville Dining Room": "17134590;entityType=restaurant",
  "Steakhouse 71": "19631278;entityType=restaurant",
  "STK Orlando": "17957969;entityType=restaurant",
  "T-REX ™": "293563;entityType=restaurant",
  "Teppan Edo": "221625;entityType=restaurant",
  "Terralina Crafted Italian": "18752054;entityType=restaurant",
  "Todd English's bluezoo": "90002274;entityType=restaurant",
  "Toledo - Tapas, Steak & Seafood": "19015837;entityType=restaurant",
  "Tony's Town Square Restaurant": "90002678;entityType=restaurant",
  "Trail's End Restaurant": "90002287;entityType=restaurant",
  "Trattoria al Forno": "17851099;entityType=restaurant",
  "Tusker House Restaurant": "90002686;entityType=restaurant",
  "Tutto Italia Ristorante": "220739;entityType=restaurant",
  "Via Napoli Ristorante e Pizzeria": "15525574;entityType=restaurant",
  "Wine Bar George": "18667615;entityType=restaurant",
  "Wolfgang Puck Bar & Grill": "19084067;entityType=restaurant",
  "Yachtsman Steakhouse": "90002412;entityType=restaurant",
  "Yak & Yeti™ Restaurant": "215686;entityType=restaurant"
}

import ReactDOM from "react-dom";
import MediaQuery from 'react-responsive';
import TextField from '@mui/material/TextField';
import Switch from 'react-ios-switch';
import { MuiTelInput, matchIsValidTel } from 'mui-tel-input';

import { SubModCard } from './cardset';
import { PaperButton } from './buttonset';

import styled from 'styled-components'

const Section = styled.div`
  width: 50%;

  display: flex;
  flex-direction: column;
`;

const SectionMobile = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
`;

function SettingsGroup(props) {

  return (
    <Section>
      {props.children}
    </Section>
    );
};

const SectionHeader = styled.div`
  margin-left: 50px;

  font-family: Open Sans;
  font-size: 18px;
  font-weight: bold;

  border-radius: 8px;
  border: none;

`;

const SectionHeaderMobile = styled.div`
  margin-left: 20px;

  font-family: Open Sans;
  font-size: 18px;
  font-weight: bold;

  border-radius: 8px;
  border: none;

`;

const SectionContainer = styled.div`
  margin: 15px 25px 15px 25px;
  padding: 15px 25px 15px 25px;

  border: 1px solid #00000020;
  border-radius: 25px;
`;

const SectionContainerMobile = styled.div`
  margin: 5px 15px 5px 15px;
  padding: 5px 15px 5px 15px;

  border: 1px solid #00000020;
  border-radius: 25px;
`;

function SettingsContainer(props) {

  return (
    <>
      <MediaQuery minWidth={1000}>
        <Section style={props.style}>
          <SectionHeader>
            {props.title}
          </SectionHeader>
          <SectionContainer>
            {props.children}
          </SectionContainer>
        </Section>
      </MediaQuery>
      <MediaQuery maxWidth={999}>
        <SectionMobile style={props.style}>
          <SectionHeaderMobile>
            {props.title}
          </SectionHeaderMobile>
          <SectionContainerMobile>
            {props.children}
          </SectionContainerMobile>
        </SectionMobile>
      </MediaQuery>
    </>
    );
};


const SFContainer = styled.div`
  font-family: Open Sans;
  font-size: 16px;
  font-weight: bold;
  color: black;

  display: flex;
  flex-direction: column;

  margin-top: 10px;
  margin-bottom: 20px;
  margin-left: 10px;
`;

const SFContainerMobile = styled.div`
  font-family: Open Sans;
  font-size: 16px;
  font-weight: bold;
  color: black;

  display: flex;
  flex-direction: column;

  margin-top: 5px;
  margin-bottom: 10px;
  margin-left: 5px;
`;

const SFBorder = styled.div`
  border-bottom: 1px solid #00000050;
  width: 100%;
`;

const SFBorderMobile = styled.div`
  border-bottom: 1px solid #00000050;
  width: 100%;
`;

const SFContent = styled.span`
  color: #00000050;
  padding: 0 10px 0 10px;
`;

const SFContentMobile = styled.span`
  color: #00000050;
  padding: 0 5px 0 5px;
`;

function SettingsField(props) {
  let input = <></>;
  let style = {};

  if (props.type == "text"){
    input = (
      <div style={{padding: "10px 0px 7px 0px"}}>
        <TextField id="outlined-basic" value={props.value} variant="outlined" size="small" fullWidth onChange={props.onChange}/>
      </div>
    );
  } else if (props.type == "email"){
    input = (
      <div style={{padding: "10px 0px 7px 0px"}}>
        <TextField id="outlined-basic" type="email" value={props.value} variant="outlined" size="small" fullWidth onChange={props.onChange}/>
      </div>
    );
    style = {
      marginTop: "0px",
    };
  } else if (props.type == "password"){
    input = (
      <div style={{padding: "10px 0px 7px 0px"}}>
        <TextField id="outlined-basic" value={props.value} type="password" variant="outlined" size="small" fullWidth onChange={props.onChange}/>
      </div>
    );
  } else if (props.type == "toggle"){
    input = (
      <div style={{padding: "10px 0px 7px 0px"}}>
        <Switch checked={props.value && !props.disabled} disabled={props.disabled} onChange={checked => {props.onChange(checked)}}/>
      </div>
    );
    style = {
      display: "flex",
      flexDirection: "row",
      position: "relative",
      justifyContent: "space-between",
      alignItems: "center",
      border: "1px solid #00000020",
      borderRadius: "5px",
      padding: "0px 10px 0px 10px",
    };
  } else if (props.type == "phone"){
    input = (
      <div style={{padding: "10px 0px 7px 0px"}}>
        <MuiTelInput preferredCountries={['US']} defaultCountry={"US"} value={props.value} onChange={props.onChange} />
      </div>
    );
    style = {
      marginTop: "0px",
    };
  } else if (props.type == "plan"){
    input = (
      <div style={{padding: "10px 0px 7px 0px"}}>
        {props.product ?
          <SubModCard
            title={props.product.shorttitle}
            price={props.product.pricetext}
            timeframe={props.product.timeframe}
            modify={<PaperButton title={"Modify"} style={{width: "60%"}} onClick={props.modify} />}
            cancel={<PaperButton title={"Cancel"} style={{width: "60%"}} disabled={(props.account.status != "active")} onClick={props.cancel} />}
            style={{width: "90%"}}
            selectable
            emboss
            key={"Flex"}
          />
          :
          <PaperButton title={"Select Plan"} style={{width: "60%"}} onClick={props.modify} />
        }
      </div>
    );
    style = {
      marginTop: "0px",
    };
  }
  return (
    <>
      <MediaQuery minWidth={1000}>
        <SFContainer style={{...props.style, ...style}}>
          {props.field}
          {input}
        </SFContainer>
      </MediaQuery>
      <MediaQuery maxWidth={999}>
        <SFContainerMobile style={{...props.style, ...style}}>
          {props.field}
          {input}
        </SFContainerMobile>
      </MediaQuery>

    </>
    );
};

export {
  SettingsField,
  SettingsContainer,
  SettingsGroup
};

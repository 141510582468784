// Import the functions you need from the SDKs you need
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

import { getFunctions, httpsCallable } from "firebase/functions";

import {
  GoogleAuthProvider,
  getAuth,
  onAuthStateChanged,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  sendEmailVerification,
  sendSignInLinkToEmail,
  isSignInWithEmailLink,
  signInWithEmailLink,
  signOut,
} from "firebase/auth";

import {
  enableIndexedDbPersistence,
  getFirestore,
  query,
  doc,
  getDocs,
  onSnapshot,
  collection,
  where,
  addDoc,
  setDoc,
  getDoc,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

import store from './app/store';
import { useSelector, useDispatch } from 'react-redux';
import {
  setCurrentUser,
  selectUser,
} from './userSlice';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDrsKc07st97GY1PQOA7jswy4XqObu20Qs",
  authDomain: "disre.xyz",
  projectId: "disre-429f0",
  storageBucket: "disre-429f0.appspot.com",
  messagingSenderId: "285700114064",
  appId: "1:285700114064:web:c93270fe76b71d1f5f3a15",
  measurementId: "G-1MYJQ09DTH"
};

// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
//   authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
//   projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
//   storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
//   messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
//   appId: process.env.REACT_APP_FIREBASE_APP_ID,
//   measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
// };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const analytics = getAnalytics(app);
const db = getFirestore(app);
const functions = getFunctions(app);

const googleProvider = new firebase.auth.GoogleAuthProvider();
googleProvider.setCustomParameters({ prompt: 'select_account' });

onAuthStateChanged(auth, (user) => {
  if (user) {
    // User is signed in, see docs for a list of available properties
    // https://firebase.google.com/docs/reference/js/firebase.User
    const uid = user.uid;
    const unsubscribe = onSnapshot(doc(db, "users", user.uid), (doc) => {
      store.dispatch(setCurrentUser(doc.data()));
    });
    //remember to unsubscribe from your realtime listener on unmount or you will create a memory leak
    return () => unsubscribe()
    // ...
  } else {
    // User is signed out
    // ...
  }
});

export async function funcTest() {
    try {
      // const rest = await getDoc(doc(db, "dining", "map"));
      const rest = await getDocs(collection(db, "requests"));
      console.log(rest.docs[0].data());
      // const functionsTest = httpsCallable(functions, 'functionsTest-2');
      // functionsTest({ data: "TEST" })
      //   .then((result) => {
      //     // Read result of the Cloud Function.
      //     // const data = result.data;
      //     console.log(result);
      //     return result;
      //   });

    } catch (e) {
        console.error(e); // handle your error here
    } finally {
        // console.log(); // cleanup, always executed
    }
}

const logInWithEmailAndPassword = async (email, password) => {
  try {
    const res = await signInWithEmailAndPassword(auth, email, password);
    const user = res.user;
    // console.log(user);
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const registerWithEmailAndPassword = async (email, password, setRegistered) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const user = res.user;

    var actionCodeSettings = {
        // URL you want to redirect back to. The domain (www.example.com) for this
        // URL must be whitelisted in the Firebase Console.
        // url: 'https://disre.xyz/',
        url: 'http://localhost:3000/#/',
        // This must be true.
        handleCodeInApp: true,
      }
    sendEmailVerification(user, actionCodeSettings)
      .then(() => {
        // The link was successfully sent. Inform the user.
        alert("Email verification sent! Be sure to check your spam folder!");
        // Save the email locally so you don't need to ask the user for it again
        // if they open the link on the same device.
        window.localStorage.setItem('emailForSignIn', email);
        // ...
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(error)
        // ...
      });

      const customer = await processStripeCustomer({email: user.email});

      await setDoc(doc(db, "users", user.uid), {
        uid: user.uid,
        name: "",
        authProvider: "local",
        email: user.email,
        phone: "",
        notificationmethod: {"email": false, "text": false},
        account: {
          type: null,
          status: null,
          subscription: null,
          stripe_timestamp: 0,
        },
        customerID: customer.data.id,
      });

      signOut(auth);
      setRegistered(true);
  } catch (err) {
    console.error(err);
    if (err.code == "auth/invalid-email") {
      alert("Please provide a valid email.")
    } else {
      alert(err.message);
    }
  }
};

const signInWithGoogle = async () => {
  try {
    const res = await signInWithPopup(auth, googleProvider);
    const user = res.user;
    const q = query(collection(db, "users"), where("uid", "==", user.uid));
    const docs = await getDocs(q);
    if (docs.docs.length === 0) {
      const customer = await processStripeCustomer({name: user.displayName, email: user.email});
      await setDoc(doc(db, "users", user.uid), {
        uid: user.uid,
        name: user.displayName,
        authProvider: "google",
        email: user.email,
        phone: "",
        notificationmethod: {"email": false, "text": false},
        account: {
          type: null,
          status: null,
          subscription: null,
          stripe_timestamp: 0,
        },
        customerID: customer.data.id,
      });
    }
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const logout = () => {
  signOut(auth);
};

export async function processPaid(product, user, subscription) {
  let er = false;
  var date = new Date(); // Now
  if (user.userRaw.account.active != null && user.userRaw.account.active.toDate() > new Date()) {
    date = user.userRaw.account.active.toDate();
  }
  date.setDate(date.getDate() + 30);
  return new Promise(function(resolve, reject) {
    var today = new Date();
    setDoc(doc(db, "users", user.uid), {
      account: {type: product.type, status: "active", subscription},
    }, {merge: true})
    .then((response) => {
      resolve(response);
    })
    .catch(function(error) {
      er = true;
      console.log(error.error);
      reject(error);
    });
  });
}

export async function processAssume(account, user) {
  let er = false;

  return new Promise(function(resolve, reject) {
    setDoc(doc(db, "users", user.uid), {
      account: account,
    }, {merge: true})
    .then((response) => {
      resolve(response);
    })
    .catch(function(error) {
      er = true;
      console.log(error.error);
      reject(error);
    });
  });
}

export async function processStripe(product, cardToken, user) {
  let er = false;

  const processStripeServer = httpsCallable(functions, 'processStripeServer');
  return new Promise(function(resolve, reject) {
    processStripeServer({product: product, cardToken: cardToken, user: user})
    .then((response) => {
      resolve(response);
    })
    .catch(function(error) {
      er = true;
      console.log(error.error);
      reject(error);
    });
  });
}

export async function processStripeCustomer(user) {
  let er = false;

  const processStripeCustomerServer = httpsCallable(functions, 'processStripeCustomerServer');
  return new Promise(function(resolve, reject) {
    processStripeCustomerServer({user: user})
    .then((response) => {
      resolve(response);
    })
    .catch(function(error) {
      er = true;
      console.log(error.error);
      reject(error);
    });
  });
}

export async function confirmStripePromo(code) {
  let er = false;

  const confirmStripePromoServer = httpsCallable(functions, 'confirmStripePromoServer');
  return new Promise(function(resolve, reject) {
    confirmStripePromoServer({code: code})
    .then((response) => {
      resolve(response);
    })
    .catch(function(error) {
      er = true;
      console.log(error.error);
      reject(error);
    });
  });
}

export async function updateStripeSubscriptionPromo(subscription, promotion_code) {
  let er = false;

  const updateStripeSubscriptionPromoServer = httpsCallable(functions, 'updateStripeSubscriptionPromoServer');
  return new Promise(function(resolve, reject) {
    updateStripeSubscriptionPromoServer({subscription: subscription, promotion_code: promotion_code})
    .then((response) => {
      resolve(response);
    })
    .catch(function(error) {
      er = true;
      console.log(error.error);
      reject(error);
    });
  });
}

export async function createStripeSubscription(product, customer, promotion_code=null) {
  let er = false;

  const createStripeSubscriptionServer = httpsCallable(functions, 'createStripeSubscriptionServer');
  return new Promise(function(resolve, reject) {
    createStripeSubscriptionServer({product: product, customer: customer, promotion_code: promotion_code})
    .then((response) => {
      resolve(response);
    })
    .catch(function(error) {
      er = true;
      console.log(error.error);
      reject(error);
    });
  });
}

export async function updateStripeSubscription(product, subscription, customer) {
  let er = false;

  const updateStripeSubscriptionServer = httpsCallable(functions, 'updateStripeSubscriptionServer');
  return new Promise(function(resolve, reject) {
    updateStripeSubscriptionServer({product: product, subscription: subscription, customer: customer})
    .then((response) => {
      resolve(response);
    })
    .catch(function(error) {
      er = true;
      console.log(error.error);
      reject(error);
    });
  });
}

export async function updateStripeSubscriptionItem(quantity, subscription) {
  let er = false;

  const updateStripeSubscriptionItemServer = httpsCallable(functions, 'updateStripeSubscriptionItemServer');
  return new Promise(function(resolve, reject) {
    updateStripeSubscriptionItemServer({quantity:quantity, subscription: subscription})
    .then((response) => {
      resolve(response);
    })
    .catch(function(error) {
      er = true;
      console.log(error.error);
      reject(error);
    });
  });
}

export async function cancelStripeSubscription(product, subscription, terminate=false) {
  let er = false;

  const cancelStripeSubscriptionServer = httpsCallable(functions, 'cancelStripeSubscriptionServer');
  return new Promise(function(resolve, reject) {
    cancelStripeSubscriptionServer({product: product, subscription: subscription, terminate: terminate})
    .then((response) => {
      resolve(response);
    })
    .catch(function(error) {
      er = true;
      console.log(error.error);
      reject(error);
    });
  });
}

export async function createPaymentIntent(product, user) {
  let er = false;

  const createPaymentIntentServer = httpsCallable(functions, 'createPaymentIntentServer');
  return new Promise(function(resolve, reject) {
    createPaymentIntentServer({product: product, user: user})
    .then((response) => {
      resolve(response);
    })
    .catch(function(error) {
      er = true;
      console.log(error.error);
      reject(error);
    });
  });
}

export async function sendTextVerificationLocal(phone) {
  let er = false;

  const sendTextVerificationServer = httpsCallable(functions, 'sendTextVerificationServer');
  return new Promise(function(resolve, reject) {
    sendTextVerificationServer({phone: phone})
    .then((response) => {
      resolve(response);
    })
    .catch(function(error) {
      er = true;
      console.log(error.error);
      reject(error);
    });
  });
}

export async function sendTextLocal(phone, message) {
  let er = false;

  const sendTextServer = httpsCallable(functions, 'sendTextServer');
  return new Promise(function(resolve, reject) {
    sendTextServer({phone: phone, message: message})
    .then((response) => {
      resolve(response);
    })
    .catch(function(error) {
      er = true;
      console.log(error.error);
      reject(error);
    });
  });
}

export async function sendEmailVerificationLocal(email) {
  let er = false;

  const sendEmailVerificationServer = httpsCallable(functions, 'sendEmailVerificationServer');
  return new Promise(function(resolve, reject) {
    sendEmailVerificationServer({email: email})
    .then((response) => {
      resolve(response);
    })
    .catch(function(error) {
      er = true;
      console.log(error.error);
      reject(error);
    });
  });
}

export async function sendEmailLocal(email, subject, message) {
  let er = false;

  const sendEmailServer = httpsCallable(functions, 'sendEmailServer');
  return new Promise(function(resolve, reject) {
    sendEmailServer({email: email, subject: subject, message: message})
    .then((response) => {
      resolve(response);
    })
    .catch(function(error) {
      er = true;
      console.log(error.error);
      reject(error);
    });
  });
}

export {
  auth,
  registerWithEmailAndPassword,
  logInWithEmailAndPassword,
  signInWithGoogle,
  logout,
  db
};

import React from "react";
import ReactDOM from "react-dom";
import { useNavigate } from "react-router-dom";

import WebFont from 'webfontloader';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled, { createGlobalStyle, keyframes, css } from "styled-components";

const MI = styled.div`
  transition: 0.5s;
  border-bottom: 1px solid #dddddd;
  cursor: pointer;
  height: 50px;
  margin: 0px -30px 0px -30px;

  display: flex;
  flex-direction: column;
  justify-content: center;

  &:hover {
    background-color: #FFFFFF50;
    transition: 0.5s;
  }
`;

const MT = styled.div`
  font-family: Montserrat Alternates, Extrabold;
  font-size: 20px;
  margin-left: 15%;

  &:hover {
    transition: 0.5s;
  }

  transition: 0.5s;
`;

const hoverStyle = {
  color: "#FF0000",
  filter: "text-shadow(0 0 120px #FF0000)",
  transition: "1s",
  userSelect: "none",
  cursor: "default"
}

const standardStyle = {
  transition: "0s",
  color: "#00000000",
}

function MenuItem(props) {
  const [hover, setHover] = React.useState(false);
  const navigate = useNavigate();
  React.useEffect(() => {

    WebFont.load({
      google: {
        families: ['Modak', 'Open Sans']
      }
    });

  }, []);

  return (
    <MI onClick={props.to ? () => navigate(props.to) : null}>
      <MT>
        {props.title}
      </MT>
    </MI>
  )
};

export {
  MenuItem
};

import React, { useEffect, useState } from "react";
import { signInWithGoogle } from "../../firebase";

function GoogleSignInButton(props) {
  const [hover, setHover] = useState(false)
  const [active, setActive] = useState(false)

  const initialStyle = {
   backgroundColor: props.theme === 'dark' ? 'rgb(66, 133, 244)' : '#fff',
   display: 'inline-flex',
   alignItems: 'center',
   color: props.theme === 'dark' ? '#fff' : 'rgba(0, 0, 0, .54)',
   boxShadow: '0 2px 2px 0 rgba(0, 0, 0, .24), 0 0 1px 0 rgba(0, 0, 0, .24)',
   padding: 0,
   borderRadius: 2,
   border: '1px solid transparent',
   fontSize: 14,
   fontWeight: '500',
   fontFamily: 'Roboto, sans-serif',
   width: "100%"
  }

 const hoverStyle = {
   cursor: 'pointer',
   opacity: 0.7,
   boxShadow: '0 0px 0px 0 rgba(0, 0, 0, .24), 0 0 0px 0 rgba(0, 0, 0, .24)',
   transition: "0.2s"
 }

  const activeStyle = {
    cursor: 'pointer',
    backgroundColor: props.theme === 'dark' ? '#3367D6' : '#eee',
    color: props.theme === 'dark' ? '#fff' : 'rgba(0, 0, 0, .54)',
    opacity: 1
  }

  const defaultStyle = {...initialStyle, ...(props.disabledStyle ? props.disabledStyle : null), ...(active ? activeStyle : null), ...(hover ? hoverStyle : null)};

  return (
     <button onClick={signInWithGoogle} style={defaultStyle}
      onMouseEnter={() => setHover(true)}
      onMouseLeave= {() => {
        setHover(false)
        setActive(false)
      }}
      onMouseDown= {() => setActive(true)}
      onMouseUp= {() => setActive(false)}
    >
      <div style={{ marginRight: 10, background: '#eee', padding: 10, borderRadius: 2 }}>
        <svg width="18" height="18" xmlns="http://www.w3.org/2000/svg">
          <g fill="#000" fillRule="evenodd">
            <path
              d="M9 3.48c1.69 0 2.83.73 3.48 1.34l2.54-2.48C13.46.89 11.43 0 9 0 5.48 0 2.44 2.02.96 4.96l2.91 2.26C4.6 5.05 6.62 3.48 9 3.48z"
              fill="#EA4335"
            />
            <path d="M17.64 9.2c0-.74-.06-1.28-.19-1.84H9v3.34h4.96c-.1.83-.64 2.08-1.84 2.92l2.84 2.2c1.7-1.57 2.68-3.88 2.68-6.62z" fill="#4285F4" />
            <path
              d="M3.88 10.78A5.54 5.54 0 0 1 3.58 9c0-.62.11-1.22.29-1.78L.96 4.96A9.008 9.008 0 0 0 0 9c0 1.45.35 2.82.96 4.04l2.92-2.26z"
              fill="#FBBC05"
            />
            <path
              d="M9 18c2.43 0 4.47-.8 5.96-2.18l-2.84-2.2c-.76.53-1.78.9-3.12.9-2.38 0-4.4-1.57-5.12-3.74L.97 13.04C2.45 15.98 5.48 18 9 18z"
              fill="#34A853"
            />
            <path fill="none" d="M0 0h18v18H0z" />
          </g>
        </svg>
      </div>
      <span style={{ paddingRight: 10, fontWeight: 500, paddingLeft: 0, paddingTop: 10, paddingBottom: 10 }}>
        Sign in with Google.
      </span>
    </button>
  )
}

export {
  GoogleSignInButton
};

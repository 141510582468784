import ReactDOM from "react-dom";
import MediaQuery from 'react-responsive';

import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from "@fortawesome/fontawesome-svg-core";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

library.add([ faXmark ]);


const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  background-color: rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(1px);

  display: flex;
  justify-content: center;
  align-items: center;

  z-index: 100;

  transition: all 0.5s;
`;

const Modal_ = styled.div`
  height: auto;
  padding: 2rem;

  border-radius: 30px;

  background-color: #03063d90;
  backdrop-filter: blur(5px);
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.2);
`;

const Modal_Header = styled.header`
  font-family: Open Sans;
  font-weight: bold;

  position: relative;
  color: white;
  border-bottom: 1px solid #dddddd;
`;

const Modal_Header_Title = styled.h2`
  textAlign: center;
`;

const Modal_Content = styled.main`
  border-bottom: 1px solid #dddddd;
  padding: 2rem 0;
`;

const Modal_Close = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border: none;
`;

//       .close {
//
//
//         img {
//           width: 1rem;
//           height: auto;
//           transition: all 0.3s;
//         }
//         &:hover {
//           img {
//             transform: scale(1.1);
//           }
//         }
//       }
//     }
//
//     &_content {
//       border-bottom: 1px solid #dddddd;
//       padding: 2rem 0;
//     }
//
//     &_footer {
//       padding: 2rem 0;
//       padding-bottom: 0;
//
//       button {
//         float: right;
//         padding: 0.5rem;
//
//         border-radius: 8px;
//       }
//       .modal-close {
//         background-color: transparent;
//         font-weight: 600;
//
//         &:hover {
//           color: rgba(54, 67, 72, 0.8);
//         }
//       }
//       .submit {
//         margin-right: 1rem;
//         background-color: #364348;
//         color: #fff;
//
//         &:hover {
//           background-color: rgba(54, 67, 72, 0.8);
//         }
//       }
//     }
//   }
// }

function Modal(props) {
  return (
    ReactDOM.createPortal(
      <>
        <ModalContainer
          onClick={() => props.close()}
        >
          <Modal_ onClick={(e) => e.stopPropagation()}>
            <Modal_Header>
              <Modal_Header_Title>{props.title}</Modal_Header_Title>
              <Modal_Close onClick={() => props.close()}>
                <FontAwesomeIcon icon={faXmark} size={"2x"} inverse />
              </Modal_Close>
            </Modal_Header>
            <Modal_Content
              style={{
                borderBottom: "1px solid #dddddd",
                padding: "2rem 0"
              }}
            >
              {props.children}
            </Modal_Content>
            <footer className="modal_footer">

            </footer>
          </Modal_>
        </ModalContainer>
      </>,
      document.getElementById("modal")
    )
  )
};

function FakeModal(props) {
  return (
    <>
      <ModalContainer
        onClick={() => props.close()}
      >
        <Modal_ onClick={(e) => e.stopPropagation()}>
          <Modal_Header>
            <Modal_Header_Title>{props.title}</Modal_Header_Title>
            <Modal_Close onClick={() => props.close()}>
              <FontAwesomeIcon icon={faXmark} size={"2x"} inverse />
            </Modal_Close>
          </Modal_Header>
          <Modal_Content
            style={{
              borderBottom: "1px solid #dddddd",
              padding: "2rem 0"
            }}
          >
            {props.children}
          </Modal_Content>
          <footer className="modal_footer">

          </footer>
        </Modal_>
      </ModalContainer>
    </>
  )
};

const OptionModalContainer = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  background-color: #FFFFFF20;
  backdrop-filter: blur(1px);

  display: flex;
  justify-content: center;
  align-items: center;

  z-index: 100;

  transition: all 0.5s;
`;

const OModal = styled.div`
  padding: 0px 20px 0px 20px;

  border-radius: 30px;

  background-color: #FFFFFFCC;
  backdrop-filter: blur(5px);
`;

const OptionModal_Header = styled.header`
  font-family: Open Sans;
  font-weight: bold;

  position: relative;
  color: black;
  border-bottom: 1px solid #dddddd;
`;

const OptionModal_Header_Title = styled.h2`
  textAlign: center;
`;

const OptionModal_Content = styled.main`
  border-bottom: 1px solid #dddddd;
  padding: 20px 0;
`;

const OptionModal_Close = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border: none;
`;

function OptionModal(props) {
  return (
    <>
      <OptionModalContainer
        onClick={() => props.close()}
      >
        <OModal onClick={(e) => e.stopPropagation()}>
          <OptionModal_Header>
            <OptionModal_Header_Title>{props.title}</OptionModal_Header_Title>
            <OptionModal_Close onClick={() => props.close()}>
              <FontAwesomeIcon icon={faXmark} size={"2x"} style={{color: "black"}} inverse />
            </OptionModal_Close>
          </OptionModal_Header>
          <OptionModal_Content
            style={{
              borderBottom: "1px solid #dddddd",
              padding: "10px 0"
            }}
          >
            {props.children}
          </OptionModal_Content>
          <footer className="modal_footer">

          </footer>
        </OModal>
      </OptionModalContainer>
    </>
  )
};

const PayModalContainer = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  background-color: #FFFFFFEE;
  backdrop-filter: blur(1px);

  display: flex;
  justify-content: center;
  align-items: center;

  z-index: 100;

  transition: all 0.5s;
`;

const PayModal = styled.div`
  height: auto;
  padding: 2rem;

  border-radius: 30px;

  background-color: #FFFFFF90;
  backdrop-filter: blur(5px);
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.2);
`;

const PayModal_Header = styled.header`
  font-family: Open Sans;
  font-weight: bold;

  position: relative;
  color: black;
  border-bottom: 1px solid #dddddd;
`;

const PayModal_Header_Title = styled.h2`
  text-align: left;
`;

const PayModal_Content = styled.main`
  border-bottom: 1px solid #dddddd;
  padding: 2rem 0;
`;

const PayModal_Close = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border: none;
`;

const PayModalContainerMobile = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  background-color: #FFFFFFEE;
  backdrop-filter: blur(1px);

  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;

  z-index: 100;

  transition: all 0.5s;
`;

const PayModalMobile = styled.div`
  padding: 10px 20px 10px 20px;

  max-height: 100%;
  overflow-y: scroll;

  border-radius: 30px;

  background-color: #FFFFFF90;
  backdrop-filter: blur(5px);
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.2);
`;

const PayModal_HeaderMobile = styled.header`
  font-family: Open Sans;
  font-weight: bold;

  position: relative;
  color: black;
  border-bottom: 1px solid #dddddd;
`;

const PayModal_Header_TitleMobile = styled.h2`
  text-align: left;
`;

const PayModal_ContentMobile = styled.main`
  padding: 20px 0px 20px 0px;
`;

const PayModal_CloseMobile = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border: none;
`;

function PaymentModal(props) {
  return (
    <>
      <MediaQuery minWidth={1000}>
        <PayModalContainer
          onClick={() => props.close()}
        >
          <PayModal onClick={(e) => e.stopPropagation()}>
            <PayModal_Header>
              <PayModal_Header_Title>{props.title}</PayModal_Header_Title>
              <PayModal_Close onClick={() => props.close()}>
                <FontAwesomeIcon icon={faXmark} size={"2x"} />
              </PayModal_Close>
            </PayModal_Header>
            <PayModal_Content
              style={{
                borderBottom: "1px solid #dddddd",
                padding: "2rem 0"
              }}
            >
              {props.children}
            </PayModal_Content>
            <footer className="modal_footer">

            </footer>
          </PayModal>
        </PayModalContainer>
      </MediaQuery>
      <MediaQuery maxWidth={999} style={{position: "relative"}}>
        <PayModalContainerMobile
          onClick={() => props.close()}
        >
          <PayModalMobile onClick={(e) => e.stopPropagation()}>
            <PayModal_HeaderMobile>
              <PayModal_Header_TitleMobile>{props.title}</PayModal_Header_TitleMobile>
              <PayModal_CloseMobile onClick={() => props.close()}>
                <FontAwesomeIcon icon={faXmark} size={"2x"} />
              </PayModal_CloseMobile>
            </PayModal_HeaderMobile>
            <PayModal_ContentMobile>
              {props.children}
            </PayModal_ContentMobile>
          </PayModalMobile>
        </PayModalContainerMobile>
      </MediaQuery>
    </>
  )
};

export {
  Modal,
  FakeModal,
  OptionModal,
  PaymentModal
};

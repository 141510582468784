import Landing from "./views/Landing.js";
import Home from "./views/Home.js";

import Account from "./views/Account.js";
import Reservations from "./views/Reservations.js";
import Settings from "./views/Settings.js";
import About from "./views/About.js";

var routes = [
  {
    path: "/landing",
    name: "Landing",
    component: Landing,
    layout: "/landing",
  },
  {
    path: "/home",
    name: "Home",
    component: Home,
    layout: "/main",
  },
  {
    path: "/account",
    name: "Account",
    component: Account,
    layout: "/main",
  },
  {
    path: "/reservations",
    name: "Reservations",
    component: Reservations,
    layout: "/main",
  },
  {
    path: "/settings",
    name: "Settings",
    component: Settings,
    layout: "/main",
  },
  {
    path: "/about",
    name: "About",
    component: About,
    layout: "/main",
  },
];

export default routes;

import React, { useEffect, useState } from "react";
import MediaQuery from 'react-responsive';
import { Route, Routes, useNavigate, useLocation } from "react-router-dom";
import { auth, registerWithEmailAndPassword, logInWithEmailAndPassword, signInWithGoogle, logout } from "../firebase";
import { useAuthState } from "react-firebase-hooks/auth";

import WebFont from 'webfontloader';
import Select from 'react-select';
import { DateTimePicker, Picklist, PicklistOption } from 'react-rainbow-components';

import TextField from '@mui/material/TextField';

import { BackgroundColorContext } from "../contexts/BackgroundColorContext";

import styled, { createGlobalStyle, keyframes, css } from "styled-components";

import { JBBack } from "../components/nuggets/JBBack";
import { GlassCard } from '../components/nuggets/cardset';
import { Divider } from '../components/nuggets/divider';
import { GlassButton, ToggleButton } from '../components/nuggets/buttonset';
import { GoogleSignInButton } from '../components/nuggets/GoogleSignInButton';

const textGradient = {
  fontFamily: 'Montserrat Alternates, Extrabold',
  fontSize: "60px",

	background: "linear-gradient(to right, #30CFD0 0%, #330867 100%)",
  // linear-gradient(to right, #30CFD0 0%, #330867 100%);
	WebkitBackgroundClip: "text",
	WebkitTextFillColor: "transparent",
}

const textGradientMobile = {
  fontFamily: 'Montserrat Alternates, Extrabold',
  fontSize: "30px",

	background: "linear-gradient(to right, #30CFD0 0%, #330867 100%)",
  // linear-gradient(to right, #30CFD0 0%, #330867 100%);
	WebkitBackgroundClip: "text",
	WebkitTextFillColor: "transparent",
}

const textBody = {
  fontFamily: 'Open Sans',
  fontSize: "24px",
  color: "black",

	// background: "linear-gradient(to right, #30CFD0 0%, #330867 100%)",
  // linear-gradient(to right, #30CFD0 0%, #330867 100%);
	// WebkitBackgroundClip: "text",
	// WebkitTextFillColor: "transparent",
}

const textBodyMobile = {
  fontFamily: 'Open Sans',
  fontSize: "16px",
  color: "black",

	// background: "linear-gradient(to right, #30CFD0 0%, #330867 100%)",
  // linear-gradient(to right, #30CFD0 0%, #330867 100%);
	// WebkitBackgroundClip: "text",
	// WebkitTextFillColor: "transparent",
}

const textHeader = {
  fontFamily: 'Montserrat Alternates, Extrabold',
  fontSize: "32px",
  // color: "black",

  background: "linear-gradient(to right, #30CFD0 0%, #330867 100%)",
	WebkitBackgroundClip: "text",
	WebkitTextFillColor: "transparent",
}

function Landing() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();
  const [registered, setRegistered] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  // const [selectedDate, setSelectedDate] = useState(new Date());

  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      return;
    }

    if (user) {
      if (60000 > (new Date().getTime() - user.metadata.createdAt)) {
        navigate("/about")
      } else {
        navigate("/main");
      }
    }
  }, [user, loading]);

  useEffect(() => {

    WebFont.load({
      google: {
        families: ['Modak', 'Open Sans']
      }
    });

  }, []);

  const handleUsernameInputChange = event => {
    setUsername(event.target.value);
  };

  const handlePasswordInputChange = event => {
    setPassword(event.target.value);
  };

  document.body.style.overflow = "hidden";
  const copy = "DisRe, putting magic back in Disney dining reservations."
  const subcopy = "Frustrated trying to score reservations for a magical Disney trip? Vacations are meant to be stress free. So stop stressing - let DisRe take over the reservation hunt. Get reservation notifications the second they pop up, email, text, and app notifications to help you score that perfect breakfast, lunch, and dinner.";
  const copyMobile = "DisRe, putting magic back in Disney dining reservations."
  const subcopyMobile = "Let DisRe handle the reservation search. Stop stressing and let the magic begin.";

  const hiddenM = [Math.floor(Math.random() * 10), Math.floor(Math.random() * 30)];

  return (
    <React.Fragment>
      <MediaQuery minWidth={1300}>
        <JBBack interactive/>

        <div style={{margin: "50px"}}>

          <div style={{position: "absolute", top: "30vh", left: "10vw", width: "45vw"}}>
            <div style={textGradient}>
              {copy}
            </div>
            <div style={{...textBody, marginTop: "20px"}}>
              {subcopy}
            </div>
          </div>

          <GlassCard title="Ready to get started?" emboss textStyle={textHeader} style={{position: "absolute", bottom: "15vh", right: "10vw"}}>
            <div style={{padding: "10px 0px 7px 0px"}}><TextField id="outlined-basic" label="email" variant="outlined" size="small" fullWidth onChange={handleUsernameInputChange}/></div>
            <div style={{padding: "7px 0px 10px 0px"}}><TextField id="outlined-basic" label="password" variant="outlined" size="small" type="password" fullWidth onChange={handlePasswordInputChange}/></div>
            <ToggleButton style={{margin: "15px 10px"}} options={["Sign In", "Register"]} onClick={[()=>logInWithEmailAndPassword(username, password), ()=>registerWithEmailAndPassword(username, password, setRegistered)]} />
            <Divider> or </Divider>
            <GoogleSignInButton style={{ width: "100%" }} />
          </GlassCard>

        </div>
      </MediaQuery>
      <MediaQuery minWidth={1000} maxWidth={1299}>
        <JBBack interactive/>

        <div style={{margin: "50px"}}>

          <div style={{position: "absolute", top: "10vh", left: "10vw", width: "45vw"}}>
            <div style={textGradient}>
              {copy}
            </div>
            <div style={{...textBody, marginTop: "20px"}}>
              {subcopy}
            </div>
          </div>

          <GlassCard title="Ready to get started?" emboss textStyle={textHeader} style={{position: "absolute", bottom: "15vh", right: "10vw"}}>
            <div style={{padding: "10px 0px 7px 0px"}}><TextField id="outlined-basic" label="email" variant="outlined" size="small" fullWidth onChange={handleUsernameInputChange}/></div>
            <div style={{padding: "7px 0px 10px 0px"}}><TextField id="outlined-basic" label="password" variant="outlined" size="small" type="password" fullWidth onChange={handlePasswordInputChange}/></div>
            <ToggleButton style={{margin: "15px 10px"}} options={["Sign In", "Register"]} onClick={[()=>logInWithEmailAndPassword(username, password), ()=>registerWithEmailAndPassword(username, password, setRegistered)]} />
            <Divider> or </Divider>
            <GoogleSignInButton style={{ width: "100%" }} />
          </GlassCard>

        </div>
      </MediaQuery>

      <MediaQuery maxWidth={999}>
        <JBBack size={45} interactive/>
        <div style={{margin: "25px"}}>

          <div style={{position: "absolute", top: "5vh", left: "5vw", width: "90vw"}}>
            <div style={textGradientMobile}>
              {copyMobile}
            </div>
            <div style={{...textBodyMobile, marginTop: "20px"}}>
              {subcopyMobile}
            </div>
          </div>

          <GlassCard title="Get started" emboss textStyle={textHeader} style={{position: "absolute", bottom: "0vh", left: "5vw", right: "5vw", textAlign: "center"}}>
            <div style={{padding: "10px 0px 7px 0px"}}><TextField id="outlined-basic" label="email" variant="outlined" size="small" fullWidth onChange={handleUsernameInputChange}/></div>
            <div style={{padding: "7px 0px 10px 0px"}}><TextField id="outlined-basic" label="password" variant="outlined" size="small" type="password" fullWidth onChange={handlePasswordInputChange}/></div>
            <ToggleButton style={{margin: "15px 10px"}} options={["Sign In", "Register"]} onClick={[()=>logInWithEmailAndPassword(username, password), ()=>registerWithEmailAndPassword(username, password, setRegistered)]} />
            <Divider> or </Divider>
            <GoogleSignInButton style={{ width: "100%" }} />
          </GlassCard>

        </div>
      </MediaQuery>

    </React.Fragment>
  );
}
export default Landing;

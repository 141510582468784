import React, { useEffect, useState } from "react";
import MediaQuery from 'react-responsive';
import ReactDOM from "react-dom";

import styled from 'styled-components'

import WebFont from 'webfontloader';

import { GlassButton, PaperButton, BasicButton } from './buttonset';
import { FullDivider } from './divider';

import Tooltip from '@mui/material/Tooltip';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faPlus,
  faEye
} from "@fortawesome/free-solid-svg-icons";

library.add([faPlus, faEye]);

const GCard = styled.div`
  min-width: 300px;
  min-height: 200px;
  background-color: #FFFFFF50;
  backdrop-filter: blur(5px);

  padding: 10px;
`;

const GCardMobile = styled.div`
  background-color: #FFFFFF50;
  backdrop-filter: blur(5px);
`;

const PCard = styled.div`
  min-width: 300px;
  min-height: 200px;
  background-color: #FFFFFFAA;
  backdrop-filter: blur(5px);

  padding: 10px;
`;

const Card_Header = styled.header`
  position: relative;
  border-bottom: 1px solid #dddddd;

  margin: 10px;
`;

const Card_HeaderMobile = styled.header`
  position: relative;
  margin: -20px 0px -20px 0px;
`;

const Card_Title = styled.h2`
  textAlign: center;
`;

const Card_Content = styled.main`
  border-bottom: 1px solid #dddddd;

  margin: 20px;
`;

const Card_Footer = styled.footer`
  display: flex;
  justify-content: center;
  align-items: center;

  bottom: 0;
`;

const gemboss = {
  boxShadow: "inset 3px 3px 7px 0 rgba(116,150,179,0.32), inset -4px -4px 6px 0 #FFFFFF",
  borderRadius: "18px",
  marginBottom: "20px",
  padding: "10px",
}

const gdeboss = {
  boxShadow: "-7px -7px 16px 0 #FFFFFF, 7px 7px 10px -4px rgba(116,150,179,0.27)",
  borderRadius: "18px",
  marginBottom: "20px",
  padding: "10px",
}

const pemboss = {
  boxShadow: "inset 1px 1px 4px 0 rgba(116,150,179,0.32), inset -2px -2px 3px 0 #FFFFFF",
  borderRadius: "18px",
  marginBottom: "20px",
  padding: "10px",
}

const pdeboss = {
  boxShadow: "-3px -3px 8px 0 #FFFFFF, 3px 3px 5px -2px rgba(116,150,179,0.27)",
  borderRadius: "18px",
  marginBottom: "20px",
  padding: "10px",
}

const pselectable = {
  outline: "none",
  borderColor: "#9ecaed",
  boxShadow: "0 0 15px #9ecaed",
  transition: "1s",
}

function GlassCard(props) {

  const cardstyle={
    ...(props.emboss ? gemboss : gdeboss)
  }

  return (
    <>
      <MediaQuery minWidth={1000}>
        <GCard style={{...cardstyle, ...props.style}}>
          <Card_Header>
            <Card_Title style={ props.textStyle }>{props.title}</Card_Title>
          </Card_Header>
          <Card_Content>
            {props.children}
          </Card_Content>
          <footer className="Card_footer">

          </footer>
        </GCard>
      </MediaQuery>
      <MediaQuery maxWidth={999}>
        <GCardMobile style={{...cardstyle, ...props.style}}>
          <Card_HeaderMobile>
            <Card_Title style={ props.textStyle }>{props.title}</Card_Title>
          </Card_HeaderMobile>
          <Card_Content>
            {props.children}
          </Card_Content>
        </GCardMobile>
      </MediaQuery>
    </>
  )
};

function PaperCard(props) {
  const [hover, setHover] = React.useState(false);

  const cardstyle={
    ...(props.emboss ? pemboss : pdeboss),
    ...(props.selectable ? (hover ? pselectable : null) : null)
  }

  return (
    <PCard style={{...cardstyle, ...props.style}} onMouseOver={()=>setHover(true)} onMouseLeave={()=>setHover(false)}>
      <Card_Header>
        <Card_Title style={ props.textStyle }>{props.title}</Card_Title>
      </Card_Header>
      <Card_Content>
        {props.children}
      </Card_Content>
      <Card_Footer className="Card_footer">
        {props.footer}
      </Card_Footer>
    </PCard>
  )
};

const LCard = styled.div`
  min-width: 300px;
  min-height: 200px;
  backdrop-filter: blur(3px);

  background: linear-gradient(to right, #30CFD030 0%, #33086730 100%);

  outline: none;
  border: 1px solid #00FFFF;

  border-radius: 10px;

  z-index: 100000;
`;

function LoFiCard(props) {
  const [hover, setHover] = React.useState(false);

  const cardstyle={
    ...(props.selectable ? (hover ? pselectable : null) : null)
  }

  return (
    <LCard style={{...cardstyle, ...props.style}} onMouseOver={()=>setHover(true)} onMouseLeave={()=>setHover(false)}>

    </LCard>
  )
};

const RCard = styled.div`
  position: relative;

  margin: 20px;

  outline: none;
  border: 1px solid #FFFFFF;
  border-radius: 10px;

  &:hover {
    -webkit-transform:rotate(1deg);

    outline: none;
    border-color: #9ecaed;
    background-color: #5DA3FA;
    box-shadow: 0 0 15px #9ecaed;
  }

  transition: 0.5s;

  overflow: hidden;
`;

const RCardMobile = styled.div`
  position: relative;

  margin: 20px;

  outline: none;
  border: 1px solid #FFFFFF;
  border-radius: 10px;

  &:hover {
    -webkit-transform:rotate(1deg);

    outline: none;
    border-color: #9ecaed;
    background-color: #5DA3FA;
    box-shadow: 0 0 15px #9ecaed;
  }

  transition: 0.5s;

  overflow: hidden;
`;

const RCardUnderlay = styled.div`
  position: absolute;
  bottom: 0px;

  width: 99%;
  height: 0%;

  background-color: #F7CD2E;

  border-radius: 20px 20px 10px 10px;
  ${RCard}:hover & {
    border: 2px solid #FFFFFF;
    height: 40%;
  }

  z-index: 1;

  transition: 0.5s;
`;

const RCardUnderlayMobile = styled.div`
  position: absolute;
  bottom: 0px;

  width: 99%;
  height: 0%;

  background-color: #F7CD2E;

  border-radius: 20px 20px 10px 10px;
  ${RCardMobile}:hover & {
    border: 2px solid #FFFFFF;
    height: 40%;
  }

  z-index: 1;

  transition: 0.5s;
`;

const RCardInfoTitle = styled.div`
  font-family: Open Sans;
  font-weight: bold;
  color: black;

  display: flex;
  justify-content: center;
  margin-top: 33%;

`;

const RCardInfoTitleMobile = styled.div`
  font-family: Open Sans;
  font-weight: bold;
  color: black;

  display: flex;
  justify-content: center;
  margin-top: 33%;

`;

const RCardInfo = styled.div`
  margin: 0px 10px 0px 10px;
  display: flex;
  justify-content: space-between;

  z-index: 2;
`;

const RCardInfoMobile = styled.div`
  margin: 0px 10px 0px 10px;
  display: flex;
  justify-content: space-between;

  z-index: 2;
`;

const RCardInfoText = styled.div`
  font-family: Open Sans;
  font-size: 20px;
  color: black;

  margin-top: 125px;

  display: flex;
  flex-direction: column;

  z-index: 2;
`;

const RCardInfoTextMobile = styled.div`
  font-family: Open Sans;
  font-size: 20px;
  color: black;

  margin-top: 50px;

  display: flex;
  flex-direction: column;

  z-index: 2;
`;

const RCardInfoTextHeader = styled.div`
  font-weight: bold;
  display: flex;
  justify-content: left;
`;

const RCardInfoTextHeaderMobile = styled.div`
  font-weight: bold;
  font-size: 12px;
  display: flex;
  justify-content: left;
`;

const RCardInfoTextContent = styled.div`
  font-weight: normal;
  display: flex;
  justify-content: right;
`;

const RCardInfoTextContentMobile = styled.div`
  font-weight: normal;
  font-size: 12px;
  display: flex;
  justify-content: right;
`;

const FAAI = styled(FontAwesomeIcon)`
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -15px 0 0 -15px;

  opacity: 0%;

  ${RCard}:hover & {
    opacity: 100%;

    transition: 0.5s;
  }

  ${RCardMobile}:hover & {
    opacity: 100%;

    transition: 0.5s;
  }

  transition: 0.5s;

  z-index: 2;
`;

const SkeletonHolder = styled.div`
  background-color: #00000020;
  backdrop-filter: blur(5px);

  border-radius: 7px;
`;

function ReservationCard(props) {
  const [hover, setHover] = React.useState(false);

  React.useEffect(() => {

    WebFont.load({
      google: {
        families: ['Modak', 'Open Sans']
      }
    });

  }, []);

  const formatTime = (time) => {
    const [hours, minutes, seconds] = time.split(":");

    var suffix = hours >= 12 ? "PM":"AM";
    return (hours > 12 ? hours-12 : hours) + ":" + minutes + " " + suffix;
  }

  const formatDate = (date) => {
    const [year, month, day] = date.split("-");
    return month + "/" + day + "/" + year.slice(-2);
  }

  if (props.reservationinfo) {
    const maxFontSize = 24;
    const fSize = (300/props.reservationinfo.name.length*1.5 > maxFontSize) ? maxFontSize : 300/props.reservationinfo.name.length*1.5;


    const maxFontSizeM = 18;
    const fSizeM = (300/props.reservationinfo.name.length*1.5 > maxFontSizeM) ? maxFontSizeM : 300/props.reservationinfo.name.length*1.5;

    return (
      <>
        <MediaQuery minWidth={1000}>
          <RCard style={{...props.style, ...{minWidth: "300px", height:"400px"}}} onMouseOver={()=>setHover(true)} onMouseLeave={()=>setHover(false)} onClick={props.onClick}>
            {props.hoverable &&
              <>
                <RCardUnderlay />
                <FAAI icon={faEye} color={"white"} size={"2x"} style={{top: "90%"}} />
              </>
            }
            <RCardInfoTitle style={{fontSize: fSize}}> {props.reservationinfo.name} </RCardInfoTitle>
            <RCardInfo>
              <RCardInfoText>
                <RCardInfoTextHeader>
                  Date:
                </RCardInfoTextHeader>
                <RCardInfoTextContent>
                  {formatDate(props.reservationinfo.date)}
                </RCardInfoTextContent>
              </RCardInfoText>
              <RCardInfoText>
                <RCardInfoTextHeader>
                  Time:
                </RCardInfoTextHeader>
                <RCardInfoTextContent>
                  {formatTime(props.reservationinfo.time)}
                </RCardInfoTextContent>
              </RCardInfoText>
              <RCardInfoText>
                <RCardInfoTextHeader>
                  Size:
                </RCardInfoTextHeader>
                <RCardInfoTextContent>
                  {props.reservationinfo.size}
                </RCardInfoTextContent>
              </RCardInfoText>
            </RCardInfo>
          </RCard>
        </MediaQuery>
        <MediaQuery maxWidth={999}>
          <RCardMobile style={{...props.style, ...{minWidth: "150px", height:"200px"}}} onMouseOver={()=>setHover(true)} onMouseLeave={()=>setHover(false)} onClick={props.onClick}>
            <RCardUnderlayMobile />
            <FAAI icon={faEye} color={"white"} size={"1x"} style={{top: "90%"}} />
            <RCardInfoTitleMobile style={{fontSize: fSizeM, textAlign: "center"}}> {props.reservationinfo.name} </RCardInfoTitleMobile>
            <RCardInfoMobile style={{marginTop: (props.reservationinfo.name.length >= 12 ? "-25px" : "0px")}}>
              <RCardInfoTextMobile>
                <RCardInfoTextHeaderMobile>
                  Date:
                </RCardInfoTextHeaderMobile>
                <RCardInfoTextContentMobile>
                  {formatDate(props.reservationinfo.date)}
                </RCardInfoTextContentMobile>
              </RCardInfoTextMobile>
              <RCardInfoTextMobile>
                <RCardInfoTextHeaderMobile>
                  Time:
                </RCardInfoTextHeaderMobile>
                <RCardInfoTextContentMobile>
                  {formatTime(props.reservationinfo.time)}
                </RCardInfoTextContentMobile>
              </RCardInfoTextMobile>
              <RCardInfoTextMobile>
                <RCardInfoTextHeaderMobile>
                  Size:
                </RCardInfoTextHeaderMobile>
                <RCardInfoTextContentMobile>
                  {props.reservationinfo.size}
                </RCardInfoTextContentMobile>
              </RCardInfoTextMobile>
            </RCardInfoMobile>
          </RCardMobile>
        </MediaQuery>
      </>
    )
  } else {
    return (
      <>
        <MediaQuery minWidth={1000}>
          <Tooltip title="Create New Reservation Alert" placement="bottom">
            <RCard style={{...props.style, ...{minWidth: "300px", height:"400px"}}} onMouseOver={()=>setHover(true)} onMouseLeave={()=>setHover(false)} onClick={props.onClick}>
              <FAAI icon={faPlus} color={"white"} size={"2x"}/>
              <div>
                <SkeletonHolder style={{margin: "20px", marginLeft: "100px", top: "10px", height: "40px"}}>  </SkeletonHolder>
                <SkeletonHolder style={{margin: "20px", marginLeft: "100px", top: "10px", height: "40px"}}>  </SkeletonHolder>
                <SkeletonHolder style={{margin: "20px", marginLeft: "100px", top: "10px", height: "40px"}}>  </SkeletonHolder>
                <SkeletonHolder style={{margin: "20px", top: "10px", height: "40px"}}> </SkeletonHolder>
                <SkeletonHolder style={{margin: "20px", bottom: "10px", minHeight: "100px"}}>  </SkeletonHolder>
              </div>
            </RCard>
          </Tooltip>
        </MediaQuery>
        <MediaQuery maxWidth={999}>
          <RCard style={{...props.style, ...{minWidth: "150px", height:"200px"}}} onMouseOver={()=>setHover(true)} onMouseLeave={()=>setHover(false)} onClick={props.onClick}>
            <FAAI icon={faPlus} color={"white"} size={"2x"}/>
            <div>
              <SkeletonHolder style={{margin: "20px", top: "10px", height: "40px"}}>  </SkeletonHolder>
              <SkeletonHolder style={{margin: "20px", top: "10px", height: "40px"}}>  </SkeletonHolder>
              <SkeletonHolder style={{margin: "20px", top: "10px", height: "40px"}}>  </SkeletonHolder>
            </div>
          </RCard>
        </MediaQuery>
      </>
    )
  }
};

const OCard = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;

  margin: 2%;
  width: 30%;
  padding: 10px;
`;

const OCardMobile = styled.div`
  width: 95%;
`;

const OCard_Header = styled.header`
  position: relative;
  border-bottom: 1px solid #dddddd;

  margin: 10px;
`;

const OCard_HeaderMobile = styled.header`
  position: relative;
  border-bottom: 1px solid #dddddd;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0px 10px 0px 10px;
`;

const CardItemPrice = styled.div`
  font-family: Montserrat Alternates, Extrabold;
  font-size: 32px;

  background: linear-gradient(to right, #30CFD0 0%, #330867 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
`;

const CardItemPriceMobile = styled.div`
  font-family: Montserrat Alternates, Extrabold;
  font-size: 16px;

  background: linear-gradient(to right, #30CFD0 0%, #330867 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
`;

const CardItemTime = styled.div`
  font-family: Montserrat Alternates, Extrabold;
  font-size: 16px;
`;

const CardItemTimeMobile = styled.div`
  font-family: Montserrat Alternates, Extrabold;
  font-size: 10px;
  margin-left: 20px;
`;

const CardItem = styled.div`
  font-family: Open Sans, Extrabold;
  font-size: 20px;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const CardItemMobile = styled.div`
  font-family: Open Sans, Extrabold;
  font-size: 12px;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const OCard_Content = styled.main`
  border-bottom: 1px solid #dddddd;

  padding-bottom: 20px;
  margin: 20px;
`;

const OCard_ContentMobile = styled.main`
  margin: 10px 10px 10px 10px;
`;

const OCard_Footer = styled.footer`
  width: 100%;
  display: flex;
  justify-content: center;
  align-self: flex-end;

  margin-top: 20px;
`;

const textHeader = {
  fontFamily: 'Montserrat Alternates, Extrabold',
  fontSize: "32px",
  // color: "black",

  background: "linear-gradient(to right, #30CFD0 0%, #330867 100%)",
	WebkitBackgroundClip: "text",
	WebkitTextFillColor: "transparent",
}

const textHeaderMobile = {
  fontFamily: 'Montserrat Alternates, Extrabold',
  fontSize: "24px",
  // color: "black",

  background: "linear-gradient(to right, #30CFD0 0%, #330867 100%)",
	WebkitBackgroundClip: "text",
	WebkitTextFillColor: "transparent",
}

function OptionCard(props) {
  const [hover, setHover] = React.useState(false);

  const cardstyle={
    ...(props.emboss ? pemboss : pdeboss),
    ...((props.selectable && !props.disabled) ? (hover ? pselectable : null) : null)
  }

  React.useEffect(() => {

    WebFont.load({
      google: {
        families: ['Modak', 'Open Sans']
      }
    });

  }, []);

  const headerContent = <>
                          <MediaQuery minWidth={1000}>
                            <div style={{display: "flex", flexDirection:"row", justifyContent:"center", marginBottom: "40px"}}>
                              <CardItemPrice >{props.price}</CardItemPrice>
                              <CardItemTime>{props.timeframe}</CardItemTime>
                            </div>
                          </MediaQuery>
                          <MediaQuery maxWidth={999}>
                            <div style={{display: "flex", flexDirection:"column", justifyContent:"flex-end"}}>
                              <CardItemPriceMobile >{props.price}</CardItemPriceMobile>
                              <CardItemTimeMobile>{props.timeframe}</CardItemTimeMobile>
                            </div>
                          </MediaQuery>
                        </>;
  const content = props.content.map((c, i) => {
    return (
      <div key={i}>
        <MediaQuery key={"mq" + i} minWidth={1000}>
          <CardItem key={c}>{c}</CardItem>
          {i!=props.content.length-1 && (
            <FullDivider key={"divider" + i} style={{paddingTop: "20px", paddingBottom: "20px", margin: "0 20px 0 20px"}}/>
          )}
        </MediaQuery>
        <MediaQuery key={"mqm" + i} maxWidth={999}>
          <CardItemMobile key={c}>{c}</CardItemMobile>
          {i!=props.content.length-1 && (
            <FullDivider key={"divider" + i} style={{paddingTop: "10px", paddingBottom: "10px", margin: "0 20px 0 20px"}}/>
          )}
        </MediaQuery>
      </div>
    )
  })

  return (
    <>
      <MediaQuery minWidth={1000}>
        <OCard style={{...cardstyle, ...props.style}} onMouseOver={()=>setHover(true)} onMouseLeave={()=>setHover(false)}>
          <Card_Header>
            <Card_Title style={ {...props.textStyle, ...textHeader} }>{props.title}</Card_Title>
          </Card_Header>
          <OCard_Content>
            {headerContent}
            {content}
            {props.children}
          </OCard_Content>
          <OCard_Footer>
            {props.footer}
          </OCard_Footer>
        </OCard>
      </MediaQuery>
      <MediaQuery maxWidth={999}>
        <OCardMobile style={{...cardstyle, ...props.style}} onMouseOver={()=>setHover(true)} onMouseLeave={()=>setHover(false)} disabled={props.disabled} onClick={(props.disabled ? null : props.onClick)}>
          <OCard_HeaderMobile>
            <Card_Title style={ {...props.textStyle, ...textHeaderMobile} }>{props.title}</Card_Title>
            {headerContent}
          </OCard_HeaderMobile>
          <OCard_ContentMobile>
            <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
              <div style={{position: "relative", width: "60%"}}>
                {content}

                {props.children}
              </div>
              {props.button}
            </div>
          </OCard_ContentMobile>
        </OCardMobile>
      </MediaQuery>
  </>
  )
};

const SMCard = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;

  margin: 2%;
  width: 30%;
  padding: 10px;
`;

const SMCardMobile = styled.div`
  width: 95%;
`;

const SMCard_Header = styled.header`
  position: relative;
  border-bottom: 1px solid #dddddd;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  margin: 10px;
`;

const SMCard_HeaderMobile = styled.header`
  position: relative;
  border-bottom: 1px solid #dddddd;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0px 10px 0px 10px;
`;

const SMCardItemPrice = styled.div`
  font-family: Montserrat Alternates, Extrabold;
  font-size: 24px;
`;

const SMCardItemPriceMobile = styled.div`
  font-family: Montserrat Alternates, Extrabold;
  font-size: 16px;
`;

const SMCardItemTime = styled.div`
  font-family: Montserrat Alternates, Extrabold;
  font-size: 16px;
  margin-top: 10px;
`;

const SMCardItemTimeMobile = styled.div`
  font-family: Montserrat Alternates, Extrabold;
  font-size: 10px;
  margin-left: 20px;
`;

const SMCardItem = styled.div`
  font-family: Open Sans, Extrabold;
  font-size: 20px;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const SMCardItemMobile = styled.div`
  font-family: Open Sans, Extrabold;
  font-size: 12px;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const SMCard_Content = styled.main`
  margin: 20px;
`;

const SMCard_ContentMobile = styled.main`
  margin: 10px 10px 10px 10px;
`;

const SMCard_Footer = styled.footer`
  width: 100%;
  display: flex;
  justify-content: center;
  align-self: flex-end;

  margin-top: 20px;
`;

function SubModCard(props) {
  const [hover, setHover] = React.useState(false);

  const cardstyle={
    ...(props.emboss ? pemboss : pdeboss),
    ...(props.selectable ? (hover ? pselectable : null) : null)
  }

  React.useEffect(() => {

    WebFont.load({
      google: {
        families: ['Modak', 'Open Sans']
      }
    });

  }, []);

  const headerContent = <>
                          <MediaQuery minWidth={1000}>
                            <div style={{display: "flex", flexDirection:"row", justifyContent:"center", marginLeft: "20px"}}>
                              <SMCardItemPrice >{props.price}</SMCardItemPrice>
                              <SMCardItemTime>{props.timeframe}</SMCardItemTime>
                            </div>
                          </MediaQuery>
                          <MediaQuery maxWidth={999}>
                            <div style={{display: "flex", flexDirection:"column", justifyContent:"flex-end"}}>
                              <SMCardItemPriceMobile >{props.price}</SMCardItemPriceMobile>
                              <SMCardItemTimeMobile>{props.timeframe}</SMCardItemTimeMobile>
                            </div>
                          </MediaQuery>
                        </>;

  return (
    <>
      <MediaQuery minWidth={1000}>
        <SMCard style={{...cardstyle, ...props.style}} onMouseOver={()=>setHover(true)} onMouseLeave={()=>setHover(false)}>
          <SMCard_Header>
            <Card_Title style={ {...props.textStyle, ...textHeader} }>{props.title}</Card_Title>
            {headerContent}
          </SMCard_Header>
          <SMCard_Content>
            <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
              {props.modify}
              {props.cancel}
            </div>
          </SMCard_Content>
        </SMCard>
      </MediaQuery>
      <MediaQuery maxWidth={999}>
        <SMCardMobile style={{...cardstyle, ...props.style}} onMouseOver={()=>setHover(true)} onMouseLeave={()=>setHover(false)} onClick={props.onClick}>
          <SMCard_HeaderMobile>
            <Card_Title style={ {...props.textStyle, ...textHeaderMobile} }>{props.title}</Card_Title>
            {headerContent}
          </SMCard_HeaderMobile>
          <SMCard_ContentMobile>
            <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
              {props.modify}
              {props.cancel}
            </div>
          </SMCard_ContentMobile>
        </SMCardMobile>
      </MediaQuery>
  </>
  )
};

export {
  GlassCard,
  PaperCard,
  LoFiCard,
  ReservationCard,
  OptionCard,
  SubModCard
};

import React, { useEffect, useState } from "react";
import { Route, Routes, useNavigate, useLocation } from "react-router-dom";
import { auth, logInWithEmailAndPassword, signInWithGoogle, logout } from "../firebase";
import { useAuthState } from "react-firebase-hooks/auth";

import WebFont from 'webfontloader';
import Select from 'react-select';
import { DateTimePicker, Picklist, PicklistOption } from 'react-rainbow-components';

import { BackgroundColorContext } from "../contexts/BackgroundColorContext";

import styled, { createGlobalStyle, keyframes, css } from "styled-components";

import { GlassCard, PaperCard } from '../components/nuggets/cardset';
import { GlassButton } from '../components/nuggets/buttonset';

const textHeader = {
  fontFamily: 'Montserrat Alternates, Extrabold',
  fontSize: "32px",
  // color: "black",

  background: "linear-gradient(to right, #30CFD0 0%, #330867 100%)",
	WebkitBackgroundClip: "text",
	WebkitTextFillColor: "transparent",
}

function Reservations() {
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      return;
    }

    if (user) {
      console.log(user);
    }
  }, [user, loading]);

  return (
    <React.Fragment>
      <div style={{margin: "50px"}}>
        <PaperCard emboss title={"Reservations"} textStyle={textHeader} style={{position: "absolute", top: "10vh", left: "22vw", right: "2vw", minWidth: "70vw", bottom: "2vh"}}>

        </PaperCard>
      </div>
    </React.Fragment>
  );
}

export default Reservations;

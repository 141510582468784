import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";

import styled from 'styled-components'

const Button = styled.div`
  background-color: #FFFFFF50;
  backdrop-filter: blur(5px);
  cursor: ${(props)=>props.disabled ? 'not-allowed' : 'pointer'};

  display: flex;
  justify-content: center;

  &:hover:not([disabled]) {
    transition: .2s;
    background-color: ${(props)=>props.hoverColor ? props.hoverColor : "#FFFFFFCC"};
    boxShadow: -7px -7px 16px 0 #FFFFFF, 7px 7px 16px -4px rgba(0,0,0,0.27);
  }
`;

const Button_Title = styled.div`
  font-size: 18px;
  padding: 0px 15px 0px 15px;
`;

const emboss = {
  boxShadow: "inset 3px 3px 7px 0 rgba(116,150,179,0.32), inset -4px -4px 6px 0 #FFFFFF",
  borderRadius: "18px",
  marginBottom: "20px",
}

const deboss = {
  boxShadow: "-4px -4px 10px 0 #FFFFFF, 4px 4px 10px -4px rgba(0,0,0,0.27)",
  borderRadius: "18px",
  marginBottom: "20px",
}

function GlassButton(props) {

  const Buttonstyle = {
    ...(props.emboss ? emboss : deboss)
  }

  return (
    <Button style={{...Buttonstyle, ...props.style}} onClick={props.onClick}>
      <Button_Title style={ props.textStyle }>{props.title}</Button_Title>
    </Button>
  )
};

function PaperButton(props) {
  const [hover, setHover] = useState(null);

  const Buttonstyle = {
    ...(props.emboss ? emboss : deboss)
  }

  return (
    <Button style={{...Buttonstyle, ...props.style}} hoverColor={props.hoverColor} disabled={props.disabled} onClick={(props.disabled ? null : props.onClick)} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
      <Button_Title style={ props.textStyle }>{props.title}</Button_Title>
    </Button>
  )
};

const BButton = styled.div`
  position: relative;
  background-color: #FFFFFF50;
  cursor: pointer;

  display: flex;
  justify-content: center;

  border-radius: 10px;

  &:hover {
    transition: 0.2s;
    background-color: #FFFFFFCC;
    boxShadow: -7px -7px 16px 0 #FFFFFF, 7px 7px 16px -4px rgba(0,0,0,0.27);
  }
`;

function BasicButton(props) {
  let sideStyle = {};
  if (props.left) {
    sideStyle["marginLeft"] = "70%";
  } else if (props.right) {
    sideStyle["marginRight"] = "70%";
  }

  return (
    <BButton style={{...sideStyle, ...props.style}} onClick={props.disabled ? null : props.onClick}>
      <Button_Title style={ props.textStyle }>{props.title}</Button_Title>
    </BButton>
  )
};

const buttonLeft = {
  borderRadius: "18px 0px 0px 18px"
}

const buttonMiddle = {

}

const buttonRight = {
  borderRadius: "0px 18px 18px 0px"
}

function ToggleButton(props) {

  const Buttonstyle = {
    ...(props.emboss ? emboss : deboss)
  }

  const buttons = props.options.map((option, i) => {
    return (
      <Button key={i} style={{...(i==0 ? buttonLeft : (i==props.options.length-1 ? buttonRight : buttonMiddle)), width: `calc(100%/${props.options.length})`}} onClick={props.onClick[i]} >
        <Button_Title style={ { ...props.textStyle, textAlign: "center", padding: "5px 5px" } }>{option}</Button_Title>
      </Button>
    );
  });

  return (
    <div style={{...Buttonstyle, ...props.style, ...{display: "flex", flexDirection: "row"}}}>
      {buttons}
    </div>
  )
};

const PayButton = styled.button`
  width: 100%;
  height: 40px;

  margin-top: 30px;

  color: white;
  background-color: #0570de;

  font-family: Open Sans;
  font-size: 18px;
  font-weight: bold;

  border-radius: 8px;
  border: none;

  &:hover {
    transition: .3s;
    background-color: #0570deCC;
  }

  transition: .3s;
`;

export {
  GlassButton,
  PaperButton,
  BasicButton,
  ToggleButton,
  PayButton
};

import React, { useEffect, useState } from "react";
import MediaQuery from 'react-responsive';
import { Route, Routes, useNavigate, useLocation } from "react-router-dom";
import { auth, logInWithEmailAndPassword, signInWithGoogle, logout, db } from "../firebase";
import { createPaymentIntent, createStripeSubscription, updateStripeSubscriptionItem } from "../firebase.js";
import { useAuthState } from "react-firebase-hooks/auth";

import { products, REACT_APP_STRIPE_PUBLISHABLE_KEY } from "../constants";
import { processPaid } from "../firebase.js";

import { sendTextLocal, sendEmailLocal } from '../firebase';

import { useSelector, useDispatch } from 'react-redux';
import {
  setCurrentUser,
  selectUser,
} from '../userSlice';

import { isInt } from "../utils/utils"

import {
  query,
  doc,
  getDocs,
  onSnapshot,
  collection,
  where,
  addDoc,
  setDoc,
  getDoc,
  limit
} from "firebase/firestore";

import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import { ElementsConsumer, CardElement } from "@stripe/react-stripe-js";

import WebFont from 'webfontloader';
import Select from 'react-select';
import { DateTimePicker, Picklist, PicklistOption } from 'react-rainbow-components';

import styled, { createGlobalStyle, keyframes, css } from "styled-components";

import { GlassCard, PaperCard, LoFiCard, ReservationCard, OptionCard } from '../components/nuggets/cardset';
import { GlassButton, PaperButton, BasicButton } from '../components/nuggets/buttonset';
import { restaurantmap } from '../dataset.js'
import { Modal, FakeModal, OptionModal, PaymentModal } from '../components/nuggets/Modal';
import CheckoutForm from '../components/nuggets/CheckoutForm';
import CheckoutFormW from '../components/nuggets/CheckoutFormW';
import SubscriptionOptions from '../components/nuggets/SubscriptionOptions';
// import "../css/bgstyles.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faCalendar,
  faRotate,
  faInfinity
} from "@fortawesome/free-solid-svg-icons";

library.add([faCalendar, faRotate, faInfinity]);

const HeaderText = styled.div`
  font-family: Open Sans;
  font-size: 24px;
  font-weight: bold;
  color: black;
`;

const HeaderTextMobile = styled.div`
  font-family: Open Sans;
  font-size: 16px;
  font-weight: bold;
  color: white;
`;

const HeaderTextPlan = styled.div`
  font-family: Open Sans;
  font-size: 32px;
  font-weight: bold;
  color: black;

  background: linear-gradient(to right, #309FFF 0%, #330867 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const HeaderTextPlanMobile = styled.div`
  font-family: Open Sans;
  font-size: 24px;
  font-weight: bold;
  color: black;

  background: linear-gradient(to right, #309FFF 0%, #330867 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const LFContainer = styled.div`
  margin-top: 20px;

  background: #e1e5f1;

  outline: none;
  border: 1px solid #FFFFFF;
  border-radius: 10px;

  display: flex;
  overflow-x: scroll;
`;

const LFContainerMobile = styled.div`
  margin-top: 20px;

  outline: none;
  border-radius: 10px;

  display: flex;
  overflow-x: scroll;
`;

function Home() {
  const live = true;
  const currentUser = useSelector(selectUser);
  const dispatch = useDispatch();

  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [pendingRequests, setPendingRequests] = useState([]);
  const [completedRequests, setCompletedRequests] = useState([]);
  const [expiredRequests, setExpiredRequests] = useState([]);

  const [modal, setModal] = useState(null);
  const [promptOptionsModal, setPromptOptionsModal] = useState(false);
  const [promptPaymentModal, setPromptPaymentModal] = useState(false);
  const [modifyModal, setModifyModal] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showApproveAdditionalDailyModal, setShowApproveAdditionalDailyModal] = useState(false);

  const [product, setProduct] = useState(null);

  const [restaurantNew, setRestaurantNew] = useState(null);
  const [sizeNew, setSizeNew] = useState(null);
  const [selectedDateNew, setSelectedDateNew] = useState(new Date());

  const [restaurantUpdate, setRestaurantUpdate] = useState(null);
  const [sizeUpdate, setSizeUpdate] = useState(null);
  const [selectedDateUpdate, setSelectedDateUpdate] = useState(null);

  const stripePromise = loadStripe(REACT_APP_STRIPE_PUBLISHABLE_KEY);
  const [clientSecret, setClientSecret] = useState(null);
  const [subscription, setSubscription] = useState(null);
  const [amount_due, setAmountDue] = useState(null);
  const [promotion_code, setPromotionCode] = useState(null);
  const [coupon, setCouponCode] = useState(null);

  const today = new Date();

  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      return;
    }

    if (user) {
      // console.log(user);
      const pq = query(collection(db, "requests"), where("user", "==", user.uid), where("status", "==", "pending"));
      const punsubscribe = onSnapshot(pq, (querySnapshot) => {
        setPendingRequests(querySnapshot.docs.map((doc) => {
          const reservationinfo = {id: doc.id, name: doc.data().location, date: doc.data().date, time: doc.data().time.replaceAll("%3A", ":"), size: doc.data().size};
          return (<ReservationCard key={doc.id} reservationinfo={reservationinfo} hoverable onClick={()=>setModifyModal(reservationinfo)}/>)
        }));
      });
      const cq = query(collection(db, "requests"), where("user", "==", user.uid), where("status", "==", "completed"));
      const cunsubscribe = onSnapshot(cq, (querySnapshot) => {
        setCompletedRequests(querySnapshot.docs.map((doc) => {
          const reservationinfo = {id: doc.id, name: doc.data().location, date: doc.data().date, time: doc.data().time.replaceAll("%3A", ":"), size: doc.data().size};
          return (<ReservationCard key={doc.id} reservationinfo={reservationinfo} />)
        }));
      });
      const eq = query(collection(db, "requests"), where("user", "==", user.uid), where("status", "==", "expired"));
      const eunsubscribe = onSnapshot(eq, (querySnapshot) => {
        setExpiredRequests(querySnapshot.docs.map((doc) => {
          const reservationinfo = {id: doc.id, name: doc.data().location, date: doc.data().date, time: doc.data().time.replaceAll("%3A", ":"), size: doc.data().size};
          return (<ReservationCard key={doc.id} reservationinfo={reservationinfo} />)
        }));
      });
    }
  }, [user, loading]);

  useEffect(() => {

    WebFont.load({
      google: {
        families: ['Modak', 'Open Sans']
      }
    });

  }, []);

  useEffect(() => {
    if (product != null) {
      createStripeSubscription(product, currentUser.userRaw.customerID)
      .then((r) => {
        setSubscription(r.data.id)
        setClientSecret(r.data.latest_invoice.payment_intent.client_secret);
        setAmountDue(r.data.latest_invoice.amount_due);
      })
      // createPaymentIntent(product, currentUser)
      // .then((r) => {
      //   console.log(r);
      //   setClientSecret(r.data.client_secret);
      // })
    }
  }, [product]);

  useEffect(() => {
    if (product != null) {
      createStripeSubscription(product, currentUser.userRaw.customerID, promotion_code)
      .then((r) => {
        setSubscription(r.data.id);
        console.log(r.data);
        if (r.data.latest_invoice.payment_intent != null){
          setClientSecret(r.data.latest_invoice.payment_intent.client_secret);
          setAmountDue(r.data.latest_invoice.amount_due);
          console.log("UPDATED SUBSCRIPTION CLIENT SECRET");
        }
        if (r.data.status == "active") {
          processPaid(product, currentUser, r.data.id);
          processed(true);
        }
      })
      // createPaymentIntent(product, currentUser)
      // .then((r) => {
      //   console.log(r);
      //   setClientSecret(r.data.client_secret);
      // })
    }
  }, [promotion_code]);

  function verifyUser(destination) {

    if (currentUser){
      if (currentUser.userRaw.account.type == null || currentUser.userRaw.account.type == "") {
        console.log("Looks like this is an inactive account");
        setPromptOptionsModal(true);
      } else {
        if (destination == "create") {
          if (isInt(products[currentUser.userRaw.account.type].maxSubscriptions)){
            if (pendingRequests.length < products[currentUser.userRaw.account.type].maxSubscriptions) {
              setShowCreateModal(true);
            }
          } else if (products[currentUser.userRaw.account.type].maxSubscriptions == "daily") {
            if (pendingRequests.length == 0){
              setShowCreateModal(true);
            } else {
              setShowApproveAdditionalDailyModal(true);
            }
          } else if (products[currentUser.userRaw.account.type].maxSubscriptions == "infinity") {
            setShowCreateModal(true);
          }
        }
      }
    }
  }

  function handleSubscription(type) {
    if (type == "magic"){
      setPromptOptionsModal(false);
      setProduct(products.magic)
      setPromptPaymentModal(true);
    } else if (type == "deluxe") {
      setPromptOptionsModal(false);
      setProduct(products.deluxe)
      setPromptPaymentModal(true);
    } else if (type == "flex") {
      setPromptOptionsModal(false);
      setProduct(products.flex)
      setPromptPaymentModal(true);
    }
  };

  function processed(value) {
    setProduct(null);
    setClientSecret(null);
    setPromptPaymentModal(false);
    setPromotionCode(null);
    setCouponCode(null);
  }

  function formatDate(date) {
    var year = date.getFullYear(),
        month = date.getMonth() + 1, // months are zero indexed
        day = date.getDate(),
        hour = date.getHours(),
        minute = date.getMinutes(),
        second = date.getSeconds(),
        hourFormatted = hour % 12 || 12, // hour returned in 24 hour format
        minuteFormatted = minute < 10 ? "0" + minute : minute,
        morning = hour < 12 ? "am" : "pm";

    const padMonth = month < 10 ? '0' + month : '' + month;

    return [year + "-" + padMonth + "-" + day, hour + "%3A" +
            minuteFormatted + "%3A" + "00"];
  }

  function createReservationAlert(data){
    if (currentUser.userRaw.account.type == "flex"){
      if (pendingRequests.length > 0){
        // Subscription quantity = current requests + 1 if current quantity > 0
        // User has 3 requests and is making 1 more: 4
        updateStripeSubscriptionItem(pendingRequests.length+1, currentUser.userRaw.account.subscription)
        .then((r) => {
          if (r.error) {
            console.log(r.error.message);
          } else {
            // console.log(r);
            // console.log(r.data.plan.metadata.type);
            // processPaid({type: r.data.plan.metadata.type}, currentUser, r.data.id);
            // processAssume({type: r.data.plan.metadata.type, status: "active"}, currentUser);
            // processed();
          }
        })
        .catch(function(error) {
          console.log(error.message);
        })
      }
    }
    if (restaurantNew == null || sizeNew == null || selectedDateNew == null){
      alert("Be sure to fill out all 3 fields to set up a reservation alert!")
    } else if (currentUser.userRaw.account.type == "deluxe" && pendingRequests.length >= 5){
      alert("Looks like you've already got 5 reservations pending! Wait until one or more have been found or delete them to open up another alert slot!")
    } else {
      const [date, time] = formatDate(data.date);

      setDoc(doc(collection(db, "requests")), {
        user: user.uid,
        status: "pending",
        location: data.restaurant,
        time: time,
        date: date,
        size: data.size,
        notified: false,
        timestamp: new Date().toISOString()
      }).then((docRef) => {
        setRestaurantNew(null);
        setSizeNew(null);
        setSelectedDateNew(new Date());
        setShowCreateModal(false);
      });

      if (currentUser.userRaw.notificationmethod.text) {
        const message = `We're on the hunt for your reservation at ${data.restaurant} for ${data.size} on ${date}! We'll let you know when we've found something for you!`;
        sendTextLocal(currentUser.userRaw.phone, message);
      }

      const subject = `Searching high and low to find your reservation!`
      const message = `We're on the hunt for your reservation at ${data.restaurant} for ${data.size} on ${date}! We'll let you know when we've found something for you!`;
      sendEmailLocal(currentUser.userRaw.email, subject, message);

      if (!currentUser.userRaw.notificationmethod.text && !currentUser.userRaw.notificationmethod.email) {
        alert("Looks like you haven't set up any notification methods yet! Be sure to update your settings to get notified when we find your reservation!")
      }
    }
  }

  function deleteReservationAlert(data){

    if (currentUser.userRaw.account.type == "flex"){
      if (pendingRequests.length > 1){
        // Subscription quantity = current requests + 1 if current quantity > 0
        // User has 3 requests and is making 1 more: 4
        updateStripeSubscriptionItem(pendingRequests.length-1, currentUser.userRaw.account.subscription)
        .then((r) => {
          if (r.error) {
            console.log(r.error.message);
          } else {
          }
        })
        .catch(function(error) {
          console.log(error.message);
        })
      }
    }

    setDoc(doc(db, "requests", data), {
      status: "deleted",
      timestamp: new Date().toISOString()
    }, {merge: true}).then((docRef) => {
      setModifyModal(false);
    });
  }

  const restaurantOptions = Object.keys(restaurantmap).sort().map((r) => ({value: r, label: r}));
  const sizeOptions = [...Array(15)].map((x, i) => ({value: i+1, label: i+1}));
  const checkoutMethod = 1;

  return (
    <React.Fragment>
      <MediaQuery minWidth={1000}>
        <LoFiCard style={{position: "absolute", top: "10px", left: "10px", right: "10px", bottom: "10px", zIndex:-1}}/>

        <div style={{margin: "50px", border: "1px solid transparent"}}>
          <LFContainer>
            <div style={{display: "flex", padding: "20px"}}>
              <HeaderText style={{marginRight: "20px"}}>
                Plan:
              </HeaderText>
              <HeaderTextPlan>
              {currentUser.uid != "" && (
                currentUser.userRaw.account.type ?
                currentUser.userRaw.account.type[0].toUpperCase() + currentUser.userRaw.account.type.substring(1)
                :
                "None"
              )}
              </HeaderTextPlan>
            </div>
          </LFContainer>
          <div style={{maxHeight: `calc(100vh - 210px)`, marginTop: "20px", overflow: "scroll"}}>
            <LFContainer style={{paddingTop: "60px", position: "relative"}}>
              <HeaderText style={{marginTop: "-35px", marginLeft: "50px", position: "absolute"}}>
                Your Reservations{(currentUser.uid != "" && currentUser.userRaw.account.type != null) && (
                  `: ${pendingRequests.length}/${(currentUser.userRaw.account.type != "magic" ? products[currentUser.userRaw.account.type].maxSubscriptions : "")}`
                )}
                {(currentUser.uid != "" && currentUser.userRaw.account.type == "magic") && (
                  <FontAwesomeIcon icon={faInfinity} />
                )}
              </HeaderText>
              {pendingRequests}
              <ReservationCard hoverable onClick={()=>verifyUser("create")}/>
            </LFContainer>
            {completedRequests.length > 0 &&
              <LFContainer style={{paddingTop: "60px", position: "relative"}}>
                <HeaderText style={{marginTop: "-35px", marginLeft: "50px", position: "absolute"}}>
                  Completed Reservations: {completedRequests.length}
                </HeaderText>
                {completedRequests}
              </LFContainer>
            }
          </div>
        </div>
        {showCreateModal &&
          <OptionModal title={"Create Alert"} close={() => setShowCreateModal(null)}>
            <div style={{ width: "400px", "fontFamily":"Open Sans", "fontSize": "16px", color: "black" }}>
              <div style={{marginBottom: "20px"}}>
                <div> Restaurant </div>
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  isLoading={isLoading}
                  isSearchable={true}
                  name="restaurant"
                  options={restaurantOptions}
                  defaultValue={restaurantNew}
                  onChange={(e) => setRestaurantNew(e.value)}
                />
              </div>
              <div style={{marginBottom: "20px"}}>
                <div> Party Size </div>
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  isLoading={isLoading}
                  isSearchable={true}
                  name="size"
                  options={sizeOptions}
                  onChange={(e) => setSizeNew(e.value)}
                />
              </div>
              <div style={{marginBottom: "20px"}}>
                <div> Reservation Date </div>
                <DateTimePicker
                  id="datetimepicker-1"
                  minDate={new Date(today)}
                  maxDate={new Date(today.setDate(today.getDate() + 60))}
                  value={selectedDateNew}
                  onChange={value => setSelectedDateNew(value)}
                  formatStyle="large"
                  locale={"en-US"}
                  icon={<FontAwesomeIcon icon={faCalendar} />}
                />
              </div>

              <BasicButton title={"Create"} onClick={()=>createReservationAlert({date:selectedDateNew, size:sizeNew, restaurant:restaurantNew})} disabled={[selectedDateNew, sizeNew, restaurantNew].includes(null)} left/>
            </div>
          </OptionModal>
        }
        {modifyModal &&
          <OptionModal title={"Modify Alert"} close={() => setModifyModal(false)}>
            <div style={{ width: "400px", "fontFamily":"Open Sans", "fontSize": "16px", color: "black" }}>
              <div style={{marginBottom: "20px"}}>
                <div> Restaurant </div>
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  defaultValue={restaurantOptions[restaurantOptions.findIndex((so) => so.value == modifyModal.name)]}
                  isLoading={isLoading}
                  isSearchable={true}
                  name="restaurant"
                  options={restaurantOptions}
                  isDisabled={true}
                />
              </div>
              <div style={{marginBottom: "20px"}}>
                <div> Party Size </div>
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  defaultValue={sizeOptions[sizeOptions.findIndex((so) => so.value == modifyModal.size)]}
                  isLoading={isLoading}
                  isSearchable={true}
                  name="size"
                  options={sizeOptions}
                  isDisabled={true}
                />
              </div>
              <div style={{marginBottom: "20px"}}>
                <div> Reservation Date </div>
                <DateTimePicker
                  id="datetimepicker-1"
                  minDate={new Date()}
                  value={new Date(modifyModal.date + "T" + modifyModal.time)}
                  onChange={value => setSelectedDateUpdate({ value })}
                  formatStyle="large"
                  locale={"en-US"}
                  icon={<FontAwesomeIcon icon={faCalendar} />}
                  portalContainer={document.getElementById('modal')}
                  disabled
                />
              </div>
              <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-end", margin: "30px, 10px, 10px, 30px"}}>
                {false &&
                  <PaperButton title={"Update"} hoverColor={"#90ee90"} disabled={[selectedDateUpdate, sizeUpdate, restaurantUpdate].includes(null)} left/>
                }
                <PaperButton title={"Delete"} hoverColor={"#ee9090"} onClick={() => deleteReservationAlert(modifyModal.id)} left/>
              </div>
            </div>
          </OptionModal>
        }
        {promptOptionsModal &&
          <OptionModal title={"Select a plan"} close={() => setPromptOptionsModal(false)}>
            <div style={{ width: "80vw", "fontFamily":"Open Sans", "fontSize": "16px", color: "black" }}>
              <div style={{display: "flex", flexDirection: "row"}}>
                <OptionCard
                  title={"Flex plan"}
                  price={products.flex.pricetext}
                  timeframe={"/ per alert / per day"}
                  content={["Pay as you go!"]}
                  footer={<PaperButton title={"Select"} style={{width: "60%"}} onClick={()=>handleSubscription("flex")} />}
                  selectable
                  emboss
                  key={"Flex"}
                />
                <OptionCard
                  title={"Deluxe plan"}
                  price={products.deluxe.pricetext}
                  timeframe={"/ per 30 days"}
                  content={["5 Active Alerts", "Priority Alerts"]}
                  footer={<PaperButton title={"Select"} style={{width: "60%"}} onClick={()=>handleSubscription("deluxe")} />}
                  selectable
                  emboss
                  key={"Deluxe"}
                />
                <OptionCard
                  title={"Magic plan"}
                  price={products.magic.pricetext}
                  timeframe={"/ per 30 days"}
                  content={["Unlimited Active Alerts", "Skip the Line Alerts"]}
                  footer={<PaperButton title={"Select"} style={{width: "60%"}} onClick={()=>handleSubscription("magic")} />}
                  selectable
                  emboss
                  key={"Magic"}
                />
              </div>
            </div>
          </OptionModal>
        }
        {showApproveAdditionalDailyModal &&
          <OptionModal title={"Approve additional alert cost"} close={() => setShowApproveAdditionalDailyModal(false)}>
            <div style={{ width: "80vw", "fontFamily":"Open Sans", "fontSize": "16px", color: "black" }}>
              <div style={{display: "flex", flexDirection: "row", margin: "30px"}}>
                Each daily alert costs {products.flex.pricetext}, you currently have {pendingRequests.length}. Creating a new alert will bring your daily subscription cost to ${(pendingRequests.length+1)*products.flex.price/100}.
              </div>
              <div style={{display: "flex", flexDirection: "row", justifyContent: "center", margin: "30px, 10px, 10px, 30px"}}>
                <PaperButton title={"Confirm"} hoverColor={"#90ee90"} style={{width: "40%", marginRight: "20px"}} onClick={()=>{setShowCreateModal(true); setShowApproveAdditionalDailyModal(false);}} />
                <PaperButton title={"Cancel"} hoverColor={"#ee9090"} style={{width: "40%", marginLeft: "20px"}} onClick={()=>setShowApproveAdditionalDailyModal(false)} />
              </div>
            </div>
          </OptionModal>
        }

        {(promptPaymentModal && currentUser.userRaw.account.subscription==null) &&
          <PaymentModal title={"Checkout"} style={{color: "black"}} close={() => setPromptPaymentModal(false)}>
            <div style={{ width: "50vw", "fontFamily": "Open Sans", "fontSize": "16px"}}>
              {checkoutMethod == 0 && stripePromise && (
                <Elements stripe={stripePromise}>
                  <ElementsConsumer>
                    {({ stripe, elements }) => (
                      <CheckoutForm stripe={stripe} elements={elements} product={product} user={currentUser} onProcessed={processed}/>
                    )}
                  </ElementsConsumer>
                </Elements>
              )}
              {checkoutMethod == 1 && stripePromise && clientSecret && (
                <Elements stripe={stripePromise} options={{clientSecret}} key={clientSecret} loader="always">
                  <CheckoutFormW product={product} amount_due={amount_due} user={currentUser} onProcessed={processed} subscription={subscription} applyPromotionCode={setPromotionCode}/>
                </Elements>
              )}
              {!stripePromise || !clientSecret && (
                <FontAwesomeIcon style={{width:"100%"}} icon={faRotate} spin/>
              )}
            </div>
          </PaymentModal>
        }
        {(promptPaymentModal && currentUser.userRaw.account.subscription!=null) &&
          <PaymentModal title={"Checkout"} style={{color: "black"}} close={() => setPromptPaymentModal(false)}>
            <div style={{ width: "50vw", "fontFamily":"Open Sans", "fontSize": "16px"}}>

            </div>
          </PaymentModal>
        }
      </MediaQuery>
      <MediaQuery maxWidth={999}>
        <LoFiCard style={{position: "absolute", top: "10px", left: "10px", right: "10px", bottom: "10px", zIndex:-1}}/>

        <div style={{paddingTop: "0px", margin: "5px", border: "1px solid transparent"}}>
          <div style={{display: "flex", flexDirection: "column", padding: "10px"}}>
            <HeaderTextMobile style={{marginLeft: "10px"}}>
              Plan:
            </HeaderTextMobile>
            <HeaderTextPlanMobile>
              {currentUser.uid != "" && (
                currentUser.userRaw.account.type ?
                currentUser.userRaw.account.type[0].toUpperCase() + currentUser.userRaw.account.type.substring(1)
                :
                "None"
              )}
            </HeaderTextPlanMobile>
          </div>
          <div style={{maxHeight: `calc(100vh - 120px)`, marginTop: "20px", overflow: "scroll"}}>
            <LFContainerMobile style={{paddingTop: "60px", margin: "10px", position: "relative"}}>
              <HeaderText style={{marginTop: "-35px", marginLeft: "10px", position: "absolute"}}>
                Your Reservations{(currentUser.uid != "" && currentUser.userRaw.account.type != null) && (
                  `: ${pendingRequests.length}/${(currentUser.userRaw.account.type != "magic" ? products[currentUser.userRaw.account.type].maxSubscriptions : "")}`
                )}
                {(currentUser.uid != "" && currentUser.userRaw.account.type == "magic") && (
                  <FontAwesomeIcon icon={faInfinity} />
                )}
              </HeaderText>
              {pendingRequests}
              <ReservationCard hoverable onClick={()=>verifyUser("create")}/>
            </LFContainerMobile>
            {completedRequests.length > 0 &&
            <LFContainerMobile style={{paddingTop: "60px", margin: "10px", position: "relative"}}>
              <HeaderText style={{marginTop: "-35px", marginLeft: "10px", position: "absolute"}}>
                Completed: {completedRequests.length}
              </HeaderText>
              {completedRequests}
            </LFContainerMobile>
          }
          </div>
        </div>
        {showCreateModal &&
          <OptionModal title={"Create Alert"} close={() => setShowCreateModal(null)}>
            <div style={{ width: "80vw", "fontFamily":"Open Sans", "fontSize": "16px", color: "black" }}>
              <div style={{marginBottom: "20px"}}>
                <div> Restaurant </div>
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  isLoading={isLoading}
                  isSearchable={true}
                  name="restaurant"
                  options={restaurantOptions}
                  defaultValue={restaurantNew}
                  onChange={(e) => setRestaurantNew(e.value)}
                />
              </div>
              <div style={{marginBottom: "20px"}}>
                <div> Party Size </div>
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  isLoading={isLoading}
                  isSearchable={true}
                  name="size"
                  options={sizeOptions}
                  onChange={(e) => setSizeNew(e.value)}
                />
              </div>
              <div style={{marginBottom: "20px"}}>
                <div> Reservation Date </div>
                <DateTimePicker
                  id="datetimepicker-1"
                  minDate={new Date(today)}
                  maxDate={new Date(today.setDate(today.getDate() + 60))}
                  value={selectedDateNew}
                  onChange={value => setSelectedDateNew(value)}
                  formatStyle="large"
                  locale={"en-US"}
                  icon={<FontAwesomeIcon icon={faCalendar} />}
                />
              </div>

              <PaperButton title={"Create"} onClick={()=>createReservationAlert({date:selectedDateNew, size:sizeNew, restaurant:restaurantNew})} disabled={[selectedDateNew, sizeNew, restaurantNew].includes(null)} left/>
            </div>
          </OptionModal>
        }
        {modifyModal &&
          <OptionModal title={"Modify Alert"} close={() => setModifyModal(false)}>
            <div style={{ width: "100%", "fontFamily":"Open Sans", "fontSize": "16px", color: "black" }}>
              <div style={{marginBottom: "20px"}}>
                Restaurant
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  defaultValue={restaurantOptions[restaurantOptions.findIndex((so) => so.value == modifyModal.name)]}
                  isLoading={isLoading}
                  isSearchable={true}
                  name="restaurant"
                  options={restaurantOptions}
                  isDisabled={true}
                />
              </div>
              <div style={{marginBottom: "20px"}}>
                Party Size
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  defaultValue={sizeOptions[sizeOptions.findIndex((so) => so.value == modifyModal.size)]}
                  isLoading={isLoading}
                  isSearchable={true}
                  name="size"
                  options={sizeOptions}
                  isDisabled={true}
                />
              </div>
              <div style={{marginBottom: "20px"}} id="modalContainer">
                Reservation Date
                <DateTimePicker
                  id="datetimepicker-1"
                  minDate={new Date()}
                  value={new Date(modifyModal.date + "T" + modifyModal.time)}
                  onChange={value => setSelectedDateUpdate({ value })}
                  formatStyle="large"
                  locale={"en-US"}
                  icon={<FontAwesomeIcon icon={faCalendar} />}
                  portalContainer={document.getElementById('modal')}
                  disabled
                />
              </div>

              <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-end", margin: "30px, 10px, 10px, 30px"}}>
                {false &&
                  <PaperButton title={"Update"} hoverColor={"#90ee90"} disabled={[selectedDateUpdate, sizeUpdate, restaurantUpdate].includes(null)} left/>
                }
                <PaperButton title={"Delete"} hoverColor={"#ee9090"} onClick={() => deleteReservationAlert(modifyModal.id)} left/>
              </div>
            </div>
          </OptionModal>
        }
        {promptOptionsModal &&
          <OptionModal title={"Select a plan"} close={() => setPromptOptionsModal(false)}>
            <div style={{ width: "80vw", "fontFamily":"Open Sans", "fontSize": "16px", color: "black" }}>
              <div style={{display: "flex", flexDirection: "column"}}>
                <OptionCard
                  title={"Flex plan"}
                  price={products.flex.pricetext}
                  timeframe={"/ per alert / per day"}
                  content={["Pay as you go!"]}
                  selectable
                  emboss
                  onClick={()=>handleSubscription("flex")}
                />
                <OptionCard
                  title={"Deluxe plan"}
                  price={products.deluxe.pricetext}
                  timeframe={"/ per 30 days"}
                  content={["5 Active Alerts", "Priority Alerts"]}
                  selectable
                  emboss
                  onClick={()=>handleSubscription("deluxe")}
                />
                <OptionCard
                  title={"Magic plan"}
                  price={products.magic.pricetext}
                  timeframe={"/ per 30 days"}
                  content={["Unlimited Active Alerts", "Skip the Line Alerts"]}
                  selectable
                  emboss
                  onClick={()=>handleSubscription("magic")}
                />
              </div>
            </div>
          </OptionModal>
        }
        {showApproveAdditionalDailyModal &&
          <OptionModal title={"Approve additional alert cost"} close={() => setShowApproveAdditionalDailyModal(false)}>
            <div style={{"fontFamily":"Open Sans", "fontSize": "16px", color: "black"}}>
              <div style={{margin: "20px"}}>
                Each daily alert costs {products.flex.pricetext}, you currently have {pendingRequests.length}. Creating a new alert will bring your daily subscription cost to ${(pendingRequests.length+1)*products.flex.price/100}.
              </div>
              <div style={{display: "flex", flexDirection: "row", justifyContent: "center", margin: "20px"}}>
                <PaperButton title={"Confirm"} hoverColor={"#90ee90"} style={{width: "40%", marginRight: "20px"}} onClick={()=>{setShowCreateModal(true); setShowApproveAdditionalDailyModal(false);}} />
                <PaperButton title={"Cancel"} hoverColor={"#ee9090"} style={{width: "40%", marginLeft: "20px"}} onClick={()=>setShowApproveAdditionalDailyModal(false)} />
              </div>
            </div>
          </OptionModal>
        }

        {(promptPaymentModal && currentUser.userRaw.account.subscription==null) &&
          <PaymentModal title={"Checkout"} style={{color: "black"}} close={() => setPromptPaymentModal(false)}>
            <div style={{"fontFamily":"Open Sans", "fontSize": "16px"}}>
              {checkoutMethod == 0 && stripePromise && (
                <Elements stripe={stripePromise}>
                  <ElementsConsumer>
                    {({ stripe, elements }) => (
                      <CheckoutForm stripe={stripe} elements={elements} product={product} user={currentUser} onProcessed={processed}/>
                    )}
                  </ElementsConsumer>
                </Elements>
              )}
              {checkoutMethod == 1 && stripePromise && clientSecret && (
                <Elements stripe={stripePromise} options={{clientSecret}} key={clientSecret} loader="always">
                  <CheckoutFormW product={product} amount_due={amount_due} user={currentUser} onProcessed={processed} subscription={subscription} applyPromotionCode={setPromotionCode}/>
                </Elements>
              )}

              {!stripePromise || !clientSecret && (
                <FontAwesomeIcon style={{width:"100%"}} icon={faRotate} spin/>
              )}
            </div>
          </PaymentModal>
        }
      </MediaQuery>
    </React.Fragment>
  );
}
export default Home;

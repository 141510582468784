import React, { useEffect, useState } from "react";
import { Route, Routes, useNavigate, useLocation } from "react-router-dom";
import { auth, logInWithEmailAndPassword, signInWithGoogle, logout } from "../firebase";
import { useAuthState } from "react-firebase-hooks/auth";

import WebFont from 'webfontloader';
import Select from 'react-select';
import { DateTimePicker, Picklist, PicklistOption } from 'react-rainbow-components';

import { BackgroundColorContext } from "../contexts/BackgroundColorContext";

import styled, { createGlobalStyle, keyframes, css } from "styled-components";

import { GlassCard, PaperCard } from '../components/nuggets/cardset';
import { GlassButton, PaperButton } from '../components/nuggets/buttonset';
import { FullDivider } from '../components/nuggets/divider';

const textHeader = {
  fontFamily: 'Montserrat Alternates, Extrabold',
  fontSize: "32px",
  // color: "black",

  background: "linear-gradient(to right, #30CFD0 0%, #330867 100%)",
	WebkitBackgroundClip: "text",
	WebkitTextFillColor: "transparent",
}

const CardItemPrice = styled.div`
  font-family: Montserrat Alternates, Extrabold;
  font-size: 32px;

  background: linear-gradient(to right, #30CFD0 0%, #330867 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
`;

const CardItemTime = styled.div`
  font-family: Montserrat Alternates, Extrabold;
  font-size: 16px;
  align-self: flex-end;
`;

const CardItem = styled.div`
  font-family: Montserrat Alternates, Extrabold;
  font-size: 20px;

  display: flex;
  justify-content: center;
  align-items: center;
`;

function Account() {
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      return;
    }

    if (user) {
      // console.log(user);
    }
  }, [user, loading]);

  return (
    <React.Fragment>
      <div style={{margin: "50px"}}>
        <PaperCard emboss title={"Account"} textStyle={textHeader} style={{position: "absolute", top: "10vh", left: "22vw", right: "2vw", minWidth: "70vw", bottom: "2vh"}}>
          <div style={{display: "flex", flexDirection: "row"}}>
            <PaperCard selectable emboss title={"Basic"} textStyle={textHeader} style={{ margin: "2%", width: "30%" }}
              footer={<PaperButton title={"Purchase"} style={{width: "80%", bottom: 0}} onClick={()=>console.log("CLICK")}/>}
            >

              <div style={{display: "flex", flexDirection:"row", justifyContent:"center"}}>
                <CardItemPrice >$1.99</CardItemPrice>
                <CardItemTime>/ per alert / per day</CardItemTime>
              </div>

              <FullDivider style={{paddingTop: "20px", paddingBottom: "20px", margin: "0 20px 0 20px"}}/>
              <CardItem>Pay per Alert per Day!</CardItem>
              <FullDivider style={{paddingTop: "20px", paddingBottom: "20px", margin: "0 20px 0 20px"}}/>
              <CardItem>Most Flexible Alert!</CardItem>
              <FullDivider style={{paddingTop: "20px", paddingBottom: "20px", margin: "0 20px 0 20px"}}/>

            </PaperCard>
            <PaperCard selectable emboss title={"Premium"} textStyle={textHeader} style={{ margin: "2%", width: "30%"}}
              footer={<PaperButton title={"Purchase"} style={{width: "80%", bottom: 0}} onClick={()=>console.log("CLICK")}/>}
            >

              <div style={{display: "flex", flexDirection:"row", justifyContent:"center"}}>
                <CardItemPrice >$19.99</CardItemPrice>
                <CardItemTime>/ per 30 days</CardItemTime>
              </div>

              <FullDivider style={{paddingTop: "20px", paddingBottom: "20px", margin: "0 20px 0 20px"}}/>
              <CardItem>5 Active Alerts</CardItem>
              <FullDivider style={{paddingTop: "20px", paddingBottom: "20px", margin: "0 20px 0 20px"}}/>
              <CardItem></CardItem>
              <FullDivider style={{paddingTop: "20px", paddingBottom: "20px", margin: "0 20px 0 20px"}}/>

            </PaperCard>
            <PaperCard selectable emboss title={"Magic"} textStyle={textHeader} style={{ margin: "2%", width: "30%"}}
              footer={<PaperButton title={"Purchase"} style={{width: "80%", bottom: 0}} onClick={()=>console.log("CLICK")}/>}
            >

              <div style={{display: "flex", flexDirection:"row", justifyContent:"center"}}>
                <CardItemPrice >$29.99</CardItemPrice>
                <CardItemTime>/ per 30 days</CardItemTime>
              </div>

              <FullDivider style={{paddingTop: "20px", paddingBottom: "20px", margin: "0 20px 0 20px"}}/>
              <CardItem>Unlimited Active Alerts</CardItem>
              <FullDivider style={{paddingTop: "20px", paddingBottom: "20px", margin: "0 20px 0 20px"}}/>
              <CardItem>Skip the Line Alerts</CardItem>
              <FullDivider style={{paddingTop: "20px", paddingBottom: "20px", margin: "0 20px 0 20px"}}/>


            </PaperCard>
          </div>
        </PaperCard>
      </div>
    </React.Fragment>
  );
}

export default Account;

import React from "react";
import ReactDOM from "react-dom/client";
import { HashRouter, Route, Routes, Navigate } from "react-router-dom";

import store from './app/store';
import { Provider } from 'react-redux';

import reportWebVitals from './reportWebVitals';

import ThemeContextWrapper from "./components/ThemeWrapper/ThemeWrapper";
import BackgroundColorWrapper from "./components/BackgroundColorWrapper/BackgroundColorWrapper";

import Landing from "./views/Landing";
import RouteHandler from "./routehandler";

import { Helmet } from 'react-helmet';
import Favicon from 'react-favicon';

import favi from './assets/favicon/favicon_v2.png';

var p = document.createElement('div');
p.id = "modal";
document.body.insertBefore(p, document.body.firstChild);

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
    <ThemeContextWrapper>
      <BackgroundColorWrapper>
        <Helmet>
          <meta charSet='utf-8' />
          <title>DisRe</title>
          <link rel="canonical" href="https://www.disre.xyz"/>
          <meta name="description" content="DisRe: Disney Dining Reservation Alerts"/>
          <link rel="icon" type="image/png" href={favi} sizes="64x64 32x32 16x16" />
        </Helmet>
        <Favicon url="/assets/favicon/favicon_v2.png"/>
        <HashRouter>
          <Routes>
            <Route exact path="/" element={<Landing />} />
            <Route path="*" element={<RouteHandler />} />
          </Routes>
        </HashRouter>
      </BackgroundColorWrapper>
    </ThemeContextWrapper>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
